import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  InputAdornment,
  Drawer,
  Checkbox,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import {
  DELETE_BUSINESS,
  UPDATE_BUSINESS_RANK,
  VERIFY_BUSINESS,
  VERIFY_BUSINESS_BY_US,
} from "src/graphql/mutations";
import { GET_BUSINESS, GET_BUSINESS_PROFILE } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import BusinessModal from "./BusinessModal";
import { toast } from "react-toastify";
import VerifyEventsDialog from "../Events/VerifyEvents";
import DeleteModel from "src/components/Common/DeleteModel";
import backButton from "../../Images/backButton.svg";
import ExcelUploadModal from "./ExcelUploadModal";
import styles from "./Business.module.css";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import SaveIcon from "@mui/icons-material/Save";
import { ClearIcon } from "@mui/x-date-pickers";
import WarningIcon from "@mui/icons-material/Warning";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ApprovalIcon from "@mui/icons-material/Approval";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { createInitialsImage } from "../Region/BusinessCard";
import MenuIcon from "@mui/icons-material/Menu";
import Select from "react-select";
import {
  DELETE_ALL_BUSINESS,
  DELETE_MULTIPLE_BUSINESS,
} from "src/graphql/DeleteMutation";
import { getRole } from "src/components/Common/Utils";
import ImageExcelUpload from "./ImageExcelUpload";
import ActionMenu from "./ActionMenu";
import { useNavigate } from "react-router";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
interface Business {
  _id: string;
  businessName: string;
  mobileNumber: string;
  status: string;
  verifiedBy: string;
}

const Business: React.FC = () => {
  const [list, setList] = useState<Business[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(
    null
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedPincodes, setSelectedPincodes] = useState<string[]>([]);
  const [selectedShowWeb, setSelectedShowWeb] = useState<string>("");
  const [selectedVerifyByUser, setSelectedVerifyByUser] = useState<string>("");
  const [selectedClaim, setSelectedClaim] = useState<string>("");
  const [subCategoryList, setSubCategoryList] = useState<string[]>([]);
  const [pinCodeList, setPinCodeList] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteBusiness] = useMutation(DELETE_BUSINESS);
  const [verifyBusinessProfile] = useMutation(VERIFY_BUSINESS);
  const [verifyBusinessProfileValue, setVerifyBusinessProfileValue] =
    useState<string>("");
  const [verifyingBusiness, setVerifyingBusiness] = useState<string | null>(
    null
  );
  const [verifyBusinessByUsProfileValue, setVerifyBusinessByUsProfileValue] =
    useState<string>("");
  const [className, setClassName] = useState<string>("");
  const [verifyingByUsBusiness, setVerifyingByUsBusiness] = useState<
    string | null
  >(null);
  const [isBusinessOpen, setIsBusinessPageOpen] = useState<boolean>(false);
  const [businessPageData, setBusinessPageData] = useState<any>();
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [deleteBusinessId, setDeleteBusinessId] = useState<string | null>(null);
  const [excelModel, setExcelModel] = useState<boolean>(false);
  const [imageModel, setImageModal] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  const [UpdateBusinessProfilesRanksBulk] = useMutation(UPDATE_BUSINESS_RANK);
  const [VerifyBusinessProfileByUs] = useMutation(VERIFY_BUSINESS_BY_US);
  const [DeleteMultipleBusinessProfiles] = useMutation(
    DELETE_MULTIPLE_BUSINESS
  );
  const [DeleteAllBusinessProfiles] = useMutation(DELETE_ALL_BUSINESS);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reason, setReason] = useState<string>("");
  const isSuperAdmin = getRole() === "SuperAdmin";
  const navigate = useNavigate();
  const OptionsFilter = [
    { label: "Approved", value: "APPROVED" },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Rejected",
      value: "REJECTED",
    },
  ];
  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            width: 50,
            sortable: false,
            filterable: false,
            columns: false,
            disableColumnSelector: true,
            disableColumnFilter: true,
            disableColumnMenu: true,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
          },
        ]
      : []),
    {
      headerName: "Logo",
      field: "businessName",
      width: 250,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (window) {
              window.open(`/bussiness/${row?._id}`, "_blank");
            }
          }}
        >
          {row.brandLogo ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img
                src={row?.bgImage ?? createInitialsImage(row?.businessName)}
                alt={row.bgImage}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  margin: 4,
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {row?.businessName}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2" color="textSecondary" mt={2}>
              {row?.businessName}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      headerName: "Category",
      field: "primarySubcategoryName",
      width: 150,
    },
    { headerName: "Mobile Number", field: "mobileNumber", width: 150 },
    { headerName: "Email", field: "businessEmail", width: 150 },
    { headerName: "Rating", field: "averageRating", width: 80 },
    { headerName: "Class", field: "classOfbusiness", width: 80 },
    { headerName: "Google Status", field: "googleBusinessStatus", width: 150 },
    { headerName: "Reason", field: "reason", width: 150 },
    ...(isSuperAdmin
      ? [{ headerName: "Verifier", field: "verifiedBy", width: 110 }]
      : []),
    {
      headerName: "Show Web",
      field: "status",
      width: 80,
      renderCell: ({ row }: any) => (
        <Tooltip title="Web live">
          {row?.status === "APPROVED" ? (
            <IconButton aria-label="status" style={{ color: "#039487" }}>
              <TaskAltIcon />
            </IconButton>
          ) : row?.status === "PENDING" ? (
            <IconButton aria-label="status" style={{ color: "#ff9966" }}>
              <WarningIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="status" style={{ color: "#ff0000" }}>
              <CloseIcon />
            </IconButton>
          )}
        </Tooltip>
      ),
    },

    {
      headerName: "Verified",
      field: "isstatusVerified",
      width: 80,
      renderCell: ({ row }: any) => (
        <Tooltip title="Verified">
          {row?.isstatusVerified === "APPROVED" ? (
            <IconButton aria-label="status" style={{ color: "#039487" }}>
              <TaskAltIcon />
            </IconButton>
          ) : row?.isstatusVerified === "PENDING" ? (
            <IconButton aria-label="status" style={{ color: "#ff9966" }}>
              <WarningIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="status" style={{ color: "#ff0000" }}>
              <CloseIcon />
            </IconButton>
          )}
        </Tooltip>
      ),
    },
    {
      headerName: "Claimed",
      field: "claimBusiness",
      width: 80,
      renderCell: ({ row }: any) => (
        <Tooltip title="claimBusiness">
          {row?.claimBusiness ? (
            <IconButton aria-label="status" style={{ color: "#039487" }}>
              <TaskAltIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="status" style={{ color: "#ff9966" }}>
              <WarningIcon />
            </IconButton>
          )}
        </Tooltip>
      ),
    },
    {
      headerName: "Rank",
      field: "rank",
      width: 90,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row.ranking || "");

        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    ...(isSuperAdmin
      ? [
          {
            headerName: "Super More",
            field: "supermore",
            width: 80,
            renderCell: ({ row }: any) => (
              <Tooltip title="claimBusiness">
                <IconButton
                  aria-label="status"
                  style={{ color: "#039487" }}
                  onClick={() => navigate(`${row?.code}/moredetails`)}
                >
                  <SupervisedUserCircleIcon />
                </IconButton>
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      headerName: "Actions",
      width: 150,
      renderCell: ({ row }: { row: Business }) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              className={styles.button}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeleteBusinessId(row._id);
                setOpenDeleteModel(true);
              }}
              aria-label="delete"
              className={styles.button}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      headerName: "Verification",
      field: "Verification",
      width: 150,
      renderCell: ({ row }: any) => (
        <>
          <Tooltip title="Web live">
            {row?.status === "APPROVED" ? (
              <IconButton
                onClick={() => setVerifyingBusiness(row._id)}
                aria-label="status"
                style={{ color: "#039487" }}
              >
                <ThumbUpAltIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setVerifyingBusiness(row._id)}
                aria-label="status"
                style={{ color: "#ff9966" }}
              >
                <WarningIcon />
              </IconButton>
            )}
          </Tooltip>

          <Tooltip title="Verify Business">
            <IconButton
              onClick={() => setVerifyingByUsBusiness(row._id)}
              aria-label="status"
              style={{ color: "#869986" }}
            >
              <ApprovalIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_BUSINESS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
      primaryCategory: selectedCategories,
      showOnWebFilter: selectedShowWeb,
      verifiedByUsFilter: selectedVerifyByUser,
      claimStatusFilter: selectedClaim,
      pinCode: selectedPincodes,
    },
  });

  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };
  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleBusinessProfiles({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleBusinessProfiles?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleBusinessProfiles?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleBusinessProfiles?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };
  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllBusinessProfiles();
      if (res?.data?.deleteAllBusinessProfiles?.statusCode === 200) {
        toast.success(res?.data?.deleteAllBusinessProfiles?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllBusinessProfiles?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };
  const handleRefetch = () => {
    refetch({
      variables: {
        page: pagination.page + 1,
        limit: pagination.pageSize,
      },
    });
  };

  useEffect(() => {
    if (data?.getBusinessProfilesAll?.data) {
      setList(data?.getBusinessProfilesAll?.data);
      setTotal(data?.getBusinessProfilesAll?.count);
      setSubCategoryList(
        data?.getBusinessProfilesAll?.subCategory?.map((item: any) => {
          return {
            label: item?.subCategoryName,
            value: item?.subCategoryName,
          };
        })
      );
      setPinCodeList(
        data?.getBusinessProfilesAll?.pinCode?.map((item: any) => {
          return {
            label: item?.areaName,
            value: item?.areaName,
          };
        })
      );
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
        primaryCategory: selectedCategories,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );
  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleEdit = async (rowData: any) => {
    if (rowData && rowData?._id) {
      window.open(`/bussiness/edit/${rowData?._id}`, "_blank");
    } else {
      console.error("Invalid row data. Unable to open edit page.");
    }
  };

  const handleDelete = async () => {
    if (!deleteBusinessId) return;
    try {
      const res = await deleteBusiness({
        variables: { _id: deleteBusinessId },
      });
      if (res?.data?.deleteBusinessProfile?.statusCode === 200) {
        toast.success(res?.data?.deleteBusinessProfile?.message);
      } else {
        throw new Error(res?.data?.deleteBusinessProfile?.message);
      }
      handleRefetch();
      setOpenDeleteModel(false);
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error deleting business:", error);
    }
  };

  const handleVerify = async (businessId: string, status: string) => {
    try {
      const res = await verifyBusinessProfile({
        variables: {
          input: {
            _id: businessId,
            status,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      handleRefetch();
      toast.success(res?.data?.verifyBusinessProfile?.message);
      setVerifyingBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };

  const handleVerifyByUs = async (businessId: string, status: string) => {
    try {
      const res = await VerifyBusinessProfileByUs({
        variables: {
          input: {
            _id: businessId,
            isstatusVerified: status,
            classOfbusiness: className,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      handleRefetch();
      toast.success(res?.data?.verifyBusinessProfileByUs?.message);
      setVerifyingByUsBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handleCloseBusinessPage = () => {
    setIsBusinessPageOpen(false);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteBusinessId(null);
  };

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      businessProfileId: id,
      rank: rankUpdates[id],
    }));

    try {
      const response = await UpdateBusinessProfilesRanksBulk({
        variables: {
          input: {
            businessProfiles: input,
          },
        },
      });
      if (response?.data?.updateBusinessProfilesRanksBulk?.statusCode === 200) {
        handleRefetch();
        toast.success(response?.data?.updateBusinessProfilesRanksBulk?.message);

        setRankUpdates({});
      } else {
        throw new Error(
          response?.data?.updateBusinessProfilesRanksBulk?.message as any
        );
      }
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error updating ranks in bulk:", error?.message);
      handleRefetch();
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };
  const handleCategoryChange = (options: any) => {
    const selectedValues = options
      ? options.map((option: any) => option.value)
      : [];
    setSelectedCategories(selectedValues);
    refetch();
  };
  const handlePincodeChange = (options: any) => {
    const selectedValues = options
      ? options.map((option: any) => option.value)
      : [];
    setSelectedPincodes(selectedValues);
    refetch();
  };
  const handleShowWebChange = (options: any) => {
    setSelectedShowWeb(options?.[0]?.value ?? "");
    refetch();
  };
  const handleVerifyUserChange = (options: any) => {
    setSelectedVerifyByUser(options?.[0]?.value ?? "");
    refetch();
  };
  const handleClaimChange = (options: any) => {
    setSelectedClaim(options?.[0]?.value ?? "");
    refetch();
  };
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorComponent />;
  }
  return (
    <>
      {openModal && (
        <BusinessModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setSelectedBusiness(null);
          }}
          business={selectedBusiness}
          refetch={handleRefetch}
        />
      )}

      {excelModel && (
        <ExcelUploadModal
          onCancel={() => setExcelModel(false)}
          refetch={refetch}
        />
      )}
      {imageModel && (
        <ImageExcelUpload
          onCancel={() => setImageModal(false)}
          refetch={refetch}
        />
      )}

      {!isBusinessOpen && !openModal && (
        <Box p={2} width={"97%"} overflow={"hidden"}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
            width={"85%"}
          >
            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              sx={{ width: 250, flexShrink: 0 }}
            >
              <Box p={2} width={250} mt={10}>
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ color: "#00C5B9" }}
                >
                  Filters
                </Typography>
                <Box my={4}>
                  <Typography variant="h6" gutterBottom>
                    Category
                  </Typography>
                  <Select
                    options={subCategoryList}
                    value={subCategoryList.filter((option: any) =>
                      selectedCategories.includes(option.value)
                    )}
                    onChange={handleCategoryChange}
                    isMulti
                    placeholder="Filter by Category"
                  />
                </Box>
                <Box my={4}>
                  <Typography variant="h6" gutterBottom>
                    Area
                  </Typography>
                  <Select
                    options={pinCodeList}
                    value={pinCodeList?.filter((option: any) =>
                      selectedPincodes?.includes(option.value)
                    )}
                    onChange={handlePincodeChange}
                    isMulti
                    placeholder="Filter by Category"
                  />
                </Box>
                <Box my={4}>
                  <Typography variant="h6" gutterBottom>
                    Show On WebFilter
                  </Typography>
                  <Select
                    options={OptionsFilter}
                    value={OptionsFilter.filter(
                      (option: any) => selectedShowWeb === option.value
                    )}
                    onChange={handleShowWebChange}
                    isMulti
                    placeholder="Filter by Category"
                  />
                </Box>
                <Box my={4}>
                  <Typography variant="h6" gutterBottom>
                    Verify By Us
                  </Typography>
                  <Select
                    options={OptionsFilter}
                    value={OptionsFilter.filter(
                      (option: any) => selectedVerifyByUser === option.value
                    )}
                    onChange={handleVerifyUserChange}
                    isMulti
                    placeholder="Filter by Category"
                  />
                </Box>
                <Box my={4}>
                  <Typography variant="h6" gutterBottom>
                    Claim Status
                  </Typography>
                  <Select
                    options={OptionsFilter}
                    value={OptionsFilter.filter(
                      (option: any) => selectedClaim === option.value
                    )}
                    onChange={handleClaimChange}
                    isMulti
                    placeholder="Filter by Category"
                  />
                </Box>
              </Box>
            </Drawer>

            <Typography variant="h4" className={styles.primaryColor}>
              Business
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
              <Box width={200}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <ActionMenu
                isSuperAdmin={isSuperAdmin}
                setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
                setOpenDeleteAllModel={setOpenDeleteAllModel}
                setImageModal={setImageModal}
                setExcelModel={setExcelModel}
                type="business"
              />
              {isRankEdit && (
                <Box width={50}>
                  <CustomButton onClick={handleRankUpdate} variant="contained">
                    <SaveIcon />
                  </CustomButton>
                </Box>
              )}
              <Box>
                <CustomButton
                  onClick={() => {
                    window.open(`/bussiness/create`, "_blank");
                  }}
                  variant="contained"
                >
                  Create
                </CustomButton>
              </Box>
              <Box>
                <IconButton
                  onClick={() => setOpenDrawer(true)}
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Divider />
          <CustomTable
            columns={COLUMNS}
            data={list}
            paginationModel={{
              page: Number(pagination.page),
              pageSize: Number(pagination.pageSize),
            }}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            totalCount={total}
          />
        </Box>
      )}

      {isBusinessOpen && businessPageData && (
        <Box p={3} sx={{ maxWidth: 1200, mx: "auto", mt: "40px" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              src={backButton}
              alt="backButton"
              height={25}
              width={25}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={handleCloseBusinessPage}
            />
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#00C5B9" }}
              marginTop={2}
            >
              Business Profile
            </Typography>
          </Box>

          {/* <BusinessProfiles businessProfile={businessPageData} /> */}
          {/* <Profile data={businessPageData} /> */}
          <Box mt={3}>
            <CustomButton
              onClick={handleCloseBusinessPage}
              color="primary"
              variant="outlined"
            >
              Close
            </CustomButton>
          </Box>
        </Box>
      )}

      <VerifyEventsDialog
        label="Business By us"
        className={className}
        setClassName={setClassName}
        handleVerify={handleVerifyByUs}
        setVerifyingData={setVerifyingByUsBusiness}
        verifyingData={verifyingByUsBusiness}
        verifyDataValue={verifyBusinessByUsProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessByUsProfileValue}
        setReason={setReason}
      />
      <VerifyEventsDialog
        label="Business Web"
        handleVerify={handleVerify}
        setVerifyingData={setVerifyingBusiness}
        verifyingData={verifyingBusiness}
        verifyDataValue={verifyBusinessProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessProfileValue}
        setReason={setReason}
      />

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this business?"
        />
      )}
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } business?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all business?"
        />
      )}
    </>
  );
};

export default Business;
