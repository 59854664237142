import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { uploadImage } from "src/components/Common/Utils";
import { BlogModalProps } from "./SubCategory";
import CustomButton from "src/components/CustomButton";
import { useMutation } from "@apollo/client";
import { ADD_BLOG, UPDATE_BLOG } from "src/graphql/mutations";
import backButton from "../../Images/backButton.svg";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./SubCategory.module.css";
import RichTextEditor from "src/components/Common/TextEditor";
import mammoth from "mammoth"; // For parsing .docx files
import * as pdfjsLib from "pdfjs-dist"; // For parsing .pdf files

const BlogPage: React.FC<BlogModalProps> = ({
  handleClose,
  newBlog,
  setNewBlog,
  isEdit = false,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(
    newBlog?.image || null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [AddBlog] = useMutation(ADD_BLOG);
  const [UpdateBlog] = useMutation(UPDATE_BLOG);

  useEffect(() => {
    if (newBlog?.image) {
      setImageUrl(newBlog.image);
    }
  }, [newBlog]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleDocumentUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type === "application/wps-office.docx") {
        // .docx file handling with mammoth.js
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        formik.setFieldValue("description", result.value);
      } else if (file.type === "application/pdf") {
        // .pdf file handling with pdfjs
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
        let textContent = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.forEach((item: any) => (textContent += item.str + " "));
        }
        formik.setFieldValue("description", textContent);
      } else {
        toast.error(
          "Unsupported file type. Please upload a .docx or .pdf file."
        );
      }
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    publishedDate: Yup.date().required("Published date is required"),
    publishedBy: Yup.string().required("Published by is required"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description should be at least 10 characters long"),
  });

  const formik = useFormik({
    initialValues: {
      title: newBlog?.title || "",
      publishedDate: newBlog?.publishedDate || "",
      publishedBy: newBlog?.publishedBy || "",
      description: newBlog?.description ?? "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let finalImageUrl = imageUrl;
        if (imageFile) {
          const res = await uploadImage(
            imageFile,
            "blog",
            formik?.values?.title
          );
          finalImageUrl = res?.uploadImage?.url;
        }

        const variables = {
          _id: isEdit ? newBlog._id : undefined,
          input: {
            ...values,
            image: finalImageUrl,
            description: values.description,
            subCategoryId: newBlog.subCategoryId,
          },
        };

        const mutation = isEdit ? UpdateBlog : AddBlog;
        const res = await mutation({ variables });

        if (res.errors) {
          throw new Error("Internal Error");
        }

        toast.success(
          isEdit ? "Blog updated successfully!" : "Blog added successfully!"
        );

        setNewBlog({
          title: "",
          image: "",
          publishedDate: "",
          publishedBy: "",
          description: "",
          createdBy: "",
          subCategoryId: newBlog.subCategoryId,
        });
      } catch (error: any) {
        toast.error(
          error.message || "An error occurred while saving the blog."
        );
        console.error("Error uploading image:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box>
      <Box className={styles.container}>
        <img
          src={backButton}
          alt="Back"
          height={25}
          width={25}
          className={styles.backButton}
          onClick={handleClose}
        />
        <Typography variant="h4" className={styles.header}>
          {isEdit ? "Update Blog Post" : "Create New Blog Post"}
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && (formik.errors.title as string)}
          />

          <Box className={styles.imageContainer}>
            <input
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="blog-image-upload"
            />
            <label htmlFor="blog-image-upload">
              {imageUrl ? (
                <>
                  <img
                    src={imageUrl}
                    alt="Blog"
                    className={styles.imagePreview}
                  />
                  <IconButton
                    className={styles.closeIcon}
                    onClick={() => {
                      setImageUrl(null);
                      setImageFile(null); // Clear imageFile as well
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <Typography color="primary">Upload Image</Typography>
              )}
            </label>
          </Box>

          <Box className={styles.imageContainer}>
            <input
              type="file"
              accept=".docx, .pdf"
              onChange={handleDocumentUpload}
              style={{ display: "none" }}
              id="document-upload"
            />
            <label htmlFor="document-upload">
              <Typography color="primary">Upload Document</Typography>
            </label>
          </Box>

          <TextField
            fullWidth
            label="Published Date"
            variant="outlined"
            type="date"
            name="publishedDate"
            InputLabelProps={{ shrink: true }}
            value={formik.values.publishedDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.publishedDate &&
              Boolean(formik.errors.publishedDate)
            }
            helperText={
              formik.touched.publishedDate &&
              (formik.errors.publishedDate as string)
            }
          />

          <TextField
            fullWidth
            label="Published By"
            variant="outlined"
            name="publishedBy"
            value={formik.values.publishedBy}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.publishedBy && Boolean(formik.errors.publishedBy)
            }
            helperText={
              formik.touched.publishedBy &&
              (formik.errors.publishedBy as string)
            }
          />

          <Box>
            <Typography>Description:</Typography>
            <RichTextEditor
              value={formik.values.description}
              onChange={(content: string) =>
                formik.setFieldValue("description", content)
              }
            />
            {formik.touched.description && formik.errors.description && (
              <Typography color="error">{formik.errors.description}</Typography>
            )}
          </Box>

          <Box>
            {loading ? (
              <CircularProgress className={styles.loadingSpinner} />
            ) : (
              <CustomButton type="submit">
                {isEdit ? "Update Blog" : "Add Blog"}
              </CustomButton>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default BlogPage;
