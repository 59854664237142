import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Checkbox,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import PopularLocationsModal from "./PopularLocationModal";
import { GET_POPULAR_LOCATION, GET_POPULAR_LOCATIONS } from "src/graphql/query";
import {
  DELETE_POPULAR_LOCATIONS,
  UPDATE_POPULAR_LOCATION_RANK,
} from "src/graphql/mutations";
import DeleteModel from "src/components/Common/DeleteModel";
import Select from "react-select";
import PopularLocationDetails from "./PopularLocationDetails";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import {
  PopularLocationCategory,
  popularLocationCategoryOptions,
} from "./popularLocationCategory";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import { ClearIcon } from "@mui/x-date-pickers";
import {
  DELETE_ALL_POPULAR_LOCATION,
  DELETE_MULTIPLE_POPULAR_LOCATION,
} from "src/graphql/DeleteMutation";
import { getRole } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";

interface Popularlocations {
  google_location_link: string;
  rank: string;
  _id: string;
  title: string;
  description: string;
  address: string;
  pincode: string;
  googlelocationlink: number;
  category: string;
}

const Popularlocations: React.FC = () => {
  const [list, setList] = useState<Popularlocations[]>([]);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [selectedPopularlocations, setSelectedPopularlocations] =
    useState<Popularlocations | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deletePopularlocationId, setDeletePopularlocationId] = useState<
    string | null
  >(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [deletePopularlocations] = useMutation(DELETE_POPULAR_LOCATIONS);
  const [filterOption, setFilterOption] = useState<
    PopularLocationCategory | "All"
  >("All");
  const [updatePopularLocationRanksBulk] = useMutation(
    UPDATE_POPULAR_LOCATION_RANK
  );
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [DeleteMultiplePopularLocations] = useMutation(
    DELETE_MULTIPLE_POPULAR_LOCATION
  );
  const [DeleteAllPopularLocations] = useMutation(DELETE_ALL_POPULAR_LOCATION);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
          },
        ]
      : []),
    {
      headerName: "Title",
      field: "title",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Box sx={{ cursor: "pointer" }} onClick={() => handleView(row)}>
          {row?.title}
        </Box>
      ),
    },
    { headerName: "Address", field: "address", flex: 1 },
    {
      headerName: "Google Location Link",
      field: "google_location_link",
      renderCell: ({ row }: { row: Popularlocations }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row?.google_location_link ?? ""}
        </div>
      ),
    },
    { headerName: "Category", field: "category", flex: 1 },
    {
      headerName: "Rank",
      field: "rank",
      flex: 1,
      renderCell: ({ row }: { row: Popularlocations }) => {
        const [rankValue, setRankValue] = useState(row.rank || "");

        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: Popularlocations }) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeletePopularlocationId(row._id);
                setOpenDeleteModel(true);
              }}
              aria-label="delete"
              style={{ color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_POPULAR_LOCATIONS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
      category: filterOption,
    },
  });
  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultiplePopularLocations({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultiplePopularLocations?.statusCode === 200) {
        toast.success(res?.data?.deleteMultiplePopularLocations?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultiplePopularLocations?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllPopularLocations();
      if (res?.data?.deleteAllPopularLocations?.statusCode === 200) {
        toast.success(res?.data?.deleteAllPopularLocations?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllPopularLocations?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };
  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data) {
      setList(data.getPopularLocations.data);
      setTotal(data.getPopularLocations.count);
    }
  }, [data]);

  const [getPopularLocation] = useLazyQuery(GET_POPULAR_LOCATION);

  const handleView = async (rowData: any) => {
    try {
      const { data } = await getPopularLocation({
        variables: {
          id: rowData._id,
        },
      });
      setSelectedPopularlocations(data?.getPopularLocationById?.data);
      setOpenViewModal(true);
    } catch (error) {
      console.error("Error fetching popular location:", error);
    }
  };

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      locationId: id,
      rank: rankUpdates[id],
    }));

    try {
      const response = await updatePopularLocationRanksBulk({
        variables: {
          input: {
            locations: input,
          },
        },
      });
      if (response?.data?.updatePopularLocationRanksBulk?.statusCode === 200) {
        toast.success(response?.data?.updatePopularLocationRanksBulk?.message);
        refetch();
        setRankUpdates({});
      } else {
        throw new Error(
          response?.data?.updatePopularLocationRanksBulk?.message as any
        );
      }
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error updating ranks in bulk:", error?.message);
      refetch();
    }
  };

  const handleEdit = async (rowData: Popularlocations) => {
    try {
      const { data } = await getPopularLocation({
        variables: {
          id: rowData._id,
        },
        fetchPolicy: "no-cache",
      });
      setSelectedPopularlocations(data?.getPopularLocationById?.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching popular location:", error);
    }
  };

  const handleDelete = async () => {
    if (!deletePopularlocationId) return;
    try {
      await deletePopularlocations({
        variables: { id: deletePopularlocationId },
      });
      refetch();
      setOpenDeleteModel(false);
    } catch (error) {
      console.error("Error deleting popular location:", error);
    }
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeletePopularlocationId(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPopularlocations(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleFilterChange = (selectedOption: any) => {
    const value = selectedOption ? selectedOption.value : "All";
    setFilterOption(value as PopularLocationCategory | "All");
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: pagination.pageSize,
      category: value === "All" ? undefined : value,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      {!openModal && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              Popular Location
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
              <Box width={200}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box width={200}>
                <Select
                  value={popularLocationCategoryOptions.find(
                    (option) => option.value === filterOption
                  )}
                  onChange={handleFilterChange}
                  options={popularLocationCategoryOptions}
                  isClearable
                  placeholder="Select Filter"
                />
              </Box>
              <ActionMenu
                isSuperAdmin={isSuperAdmin}
                setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
                setOpenDeleteAllModel={setOpenDeleteAllModel}
              />
              {isRankEdit && (
                <Box width={50}>
                  <CustomButton onClick={handleRankUpdate} variant="contained">
                    <SaveIcon />
                  </CustomButton>
                </Box>
              )}
              <Box>
                <CustomButton
                  onClick={() => setOpenModal(true)}
                  variant="contained"
                >
                  Create
                </CustomButton>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              paginationModel={{
                page: pagination.page,
                pageSize: pagination.pageSize,
              }}
              totalCount={total}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </Box>
        </>
      )}

      {openModal && (
        <PopularLocationsModal
          open={openModal}
          handleClose={handleCloseModal}
          popularlocations={selectedPopularlocations}
          refetchPopularlocations={refetch}
        />
      )}

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this popular location?"
        />
      )}

      {openViewModal && (
        <PopularLocationDetails
          locationData={selectedPopularlocations}
          onClose={() => setOpenViewModal(false)}
          open={openViewModal}
        />
      )}
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } popular location?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all popular location?"
        />
      )}
    </Box>
  );
};

export default Popularlocations;
