export const ROUTES = {
  HOME: "/home",
  LOGIN: "/login",
  USERS: "/users",
  USERS_DETAILS: "/users/:id",
  PINCODES: "/pincode",
  DEFAULT: "/events",
  EVENTS: "/events",
  EVENTS_DETAILS: "/events/:id",
  NEWS: "/news",
  NEWS_DETAILS: "/news/:id",
  BUSINESS: "/bussiness",
  BUSSINESS_DETAILS: "/bussiness/:id",
  BUSSINESS_CREATE: "/bussiness/create",
  BUSSINESS_EDIT: "/bussiness/edit/:id",
  BUSSINESS_SUPER_DETAILS: "/bussiness/:id/moredetails",
  DEALS: "/deals",
  DEALS_DETAILS: "/deals/:id",
  CATEGORY: "/category",
  CATEGORY_DETAILS: "/category/:id",
  SUB_CATEGORY: "/subcategory/:id",
  SUB_CATEGORY_DETAILS: "/subcategory/:id",
  BLOGS: "/blogs",
  BLOGS_DETAILS: "/blogs/:id",
  FAQ: "/faqs",
  ADVERTISE: "/advertise",
  CONTACT_US: "/contact-us",
  TAGS: "/tags",
  POPULAR_LOCATION: "/popular-location",
  CATEGORY_LIST: "/category/:id",
  HOME_PAGE_SETTINGS: "/homepagesettings",
  TRACKING_DATA: "/tracking-data",
  REGION: "/region",
  CLAIM_BUSINESS: "/claimbusiness",
  DEAL_ENUM: "/deal-enum",
  EVENT_ENUM: "/event-enum",
  CONFIG: "/config",
  ENUM: "/enum",
};
