import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import BusinessCard from "./BusinessCard";
import BusinessProfiles from "src/pages/Business/BusinessProfile";
import { Business } from "./types";
import CustomButton from "src/components/CustomButton";
import backButton from "../../Images/backButton.svg";

interface BusinessListProps {
  businesses: Business[];
}

const BusinessList: React.FC<BusinessListProps> = ({ businesses }) => {
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (business: Business) => {
    setSelectedBusiness(business);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedBusiness(null);
    setOpen(false);
  };

  return (
    <Box sx={{ padding: 2, maxWidth: 1600 }}>
      {selectedBusiness && open ? (
        <Box p={3} sx={{ maxWidth: 1200, mt: "40px" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              src={backButton}
              alt="backButton"
              height={25}
              width={25}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#00C5B9" }}
              marginTop={2}
            >
              Business Profile
            </Typography>
          </Box>

          {/* <BusinessProfiles businessProfile={selectedBusiness} /> */}
          <Box mt={3}>
            <CustomButton
              onClick={() => setOpen(false)}
              color="primary"
              variant="outlined"
            >
              Close
            </CustomButton>
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="h4" gutterBottom>
            Businesses
          </Typography>
          <Grid container>
            {businesses?.map((business) => (
              <Grid item xs={12} sm={6} md={3} key={business._id}>
                <BusinessCard business={business} onViewDetails={handleOpen} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default BusinessList;
