import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  IconButton,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_CONFIG_DATA, UPDATE_CONFIG_DATA } from "src/graphql/mutations";
import { GET_CONFIG_DATA } from "src/graphql/query";
import { toast } from "react-toastify";
import { uploadImages } from "src/components/Common/Utils";
import CustomButton from "src/components/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";

interface ConfigDataModalProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
  configData?: any;
}

const ConfigDataModal: React.FC<ConfigDataModalProps> = ({
  open,
  onClose,
  refetch,
  configData,
}) => {
  const [formData, setFormData] = useState<any>(configData?.configData || {});
  const [newSectionName, setNewSectionName] = useState("");
  const [isBannerEnabled, setIsBannerEnabled] = useState(false);
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerImages, setBannerImages] = useState<any[]>([]);
  const [vipDesktopBanners, setVipDesktopBanners] = useState<any[]>([]);
  const [vipMobileBanners, setVipMobileBanners] = useState<any[]>([]);
  const [newField, setNewField] = useState<{
    [key: string]: { field: string; value: string };
  }>({});

  useEffect(() => {
    setFormData(configData?.configData || {});
    setIsBannerEnabled(configData?.configData?.Home?.bannerEnabled || false);
    setBannerTitle(configData?.configData?.Home?.bannerTitle || "");
    setBannerImages(configData?.configData?.Home?.banners || []);
    setVipDesktopBanners(configData?.configData?.Home?.vipDesktopBanners || []);
    setVipMobileBanners(configData?.configData?.Home?.vipMobileBanners || []);
  }, [configData]);

  const [createConfigData] = useMutation(CREATE_CONFIG_DATA, {
    onCompleted: () => {
      refetch();
      resetForm();
      onClose();
    },
  });

  const [updateConfigData] = useMutation(UPDATE_CONFIG_DATA, {
    onCompleted: () => {
      refetch();
      resetForm();
      onClose();
    },
  });

  const handleFieldChange = (section: string, field: string, value: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleDeleteSection = (section: string) => {
    setFormData((prevData: any) => {
      const updatedData = { ...prevData };
      delete updatedData[section];
      return updatedData;
    });
  };

  const handleAddSection = () => {
    if (!newSectionName.trim()) {
      toast.error("Please enter a valid section name.");
      return;
    }

    if (!formData[newSectionName]) {
      setFormData((prevData: any) => ({
        ...prevData,
        [newSectionName]: {
          metaTitle: "",
          metaDescription: "",
          metaKeywords: "",
          focusKeyword: "",
        },
      }));
      setNewSectionName("");
    } else {
      toast.error("Section already exists");
    }
  };

  const handleSave = async () => {
    try {
      const uploadedImageUrls = await Promise.all(
        bannerImages.map(async (image: File | string) => {
          if (typeof image !== "string") {
            const uploadedImage = await uploadImages([image], "banner", "Home");
            return uploadedImage?.uploadMultipleImages?.[0]?.url || "";
          } else {
            return image;
          }
        })
      );

      const uploadedVipDesktopUrls = await Promise.all(
        vipDesktopBanners.map(async ({ url }: { url: File | string }) => {
          if (typeof url !== "string") {
            const uploadedImage = await uploadImages(
              [url],
              "desktopBanner",
              "Home"
            );
            return uploadedImage?.uploadMultipleImages?.[0]?.url || "";
          } else {
            return url;
          }
        })
      );

      const uploadedVipMobileUrls = await Promise.all(
        vipMobileBanners.map(async ({ url }: { url: File | string }) => {
          if (typeof url !== "string") {
            const uploadedImage = await uploadImages(
              [url],
              "mobileBanner",
              "Home"
            );
            return uploadedImage?.uploadMultipleImages?.[0]?.url || "";
          } else {
            return url;
          }
        })
      );

      const updatedFormData = {
        ...formData,
        Home: {
          ...(formData?.Home || {}),
          bannerEnabled: isBannerEnabled,
          bannerTitle,
          banners: uploadedImageUrls,
          vipDesktopBanners: uploadedVipDesktopUrls?.map((url, idx) => ({
            url,
            title: vipDesktopBanners[idx].title,
          })),
          vipMobileBanners: uploadedVipMobileUrls?.map((url, idx) => ({
            url,
            title: vipMobileBanners[idx].title,
          })),
        },
      };

      const inputData = { configData: updatedFormData };

      if (configData) {
        const res = await updateConfigData({
          variables: { _id: configData._id, input: inputData },
        });
        if (res?.data?.updateConfigData?.statusCode === 200) {
          toast.success(
            res?.data?.updateConfigData?.message ||
              "Config data updated successfully"
          );
        } else {
          throw new Error(res?.data?.updateConfigData?.message);
        }
      } else {
        const res = await createConfigData({
          variables: { input: inputData },
          refetchQueries: [{ query: GET_CONFIG_DATA }],
        });
        if (res?.data?.addConfigData?.statusCode === 200) {
          toast.success(
            res?.data?.addConfigData?.message ||
              "Config data created successfully"
          );
        } else {
          throw new Error(res?.data?.addConfigData?.message);
        }
      }
    } catch (error: any) {
      toast.error(error.message);
      console.error(error.message);
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setBannerImages((prevImages) => [...prevImages, file]);
    }
  };

  const handleVipImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImages: React.Dispatch<any>
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setImages((prevImages: any[]) => [
        ...prevImages,
        { url: file, title: "" },
      ]);
    }
  };

  const handleDeleteBannerImage = (index: number) => {
    setBannerImages((prevImages) =>
      prevImages.filter((_, idx) => idx !== index)
    );
  };
  const handleDeleteVipImage = (
    index: number,
    setImages: React.Dispatch<any>
  ) => {
    setImages((prevImages: any[]) =>
      prevImages.filter((_, idx) => idx !== index)
    );
  };

  const handleAddField = (section: string) => {
    const { field, value } = newField[section] || {};
    if (!field || field.trim() === "") {
      toast.error("Please enter a valid field name.");
      return;
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value || "",
      },
    }));
    setNewField((prevFields) => ({
      ...prevFields,
      [section]: { field: "", value: "" },
    }));
  };

  const resetForm = () => {
    setFormData(configData?.configData || {});
    setIsBannerEnabled(false);
    setBannerTitle("");
    setBannerImages([]);
    setVipDesktopBanners([]);
    setVipMobileBanners([]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ paddingY: 4 }}
    >
      <DialogTitle>
        {configData ? "Update Config Data" : "Create Config Data"}
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Typography variant="h6">Add New Section</Typography>
          <TextField
            label="Section Name"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
            fullWidth
            sx={{ mt: 1 }}
          />
          <CustomButton
            variant="contained"
            onClick={handleAddSection}
            sx={{ mt: 1 }}
          >
            Add Section
          </CustomButton>
        </Box>

        <Box mt={4}>
          <Typography variant="h6">Banner Section</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isBannerEnabled}
                onChange={(e) => setIsBannerEnabled(e.target.checked)}
                name="bannerEnabled"
                color="primary"
              />
            }
            label="Enable Banner"
          />
          {isBannerEnabled && (
            <>
              <TextField
                label="Banner Title"
                value={bannerTitle}
                onChange={(e) => setBannerTitle(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              {bannerImages.map((image, index) => (
                <Box display="flex" alignItems="center" key={index} mt={2}>
                  <img
                    src={
                      typeof image !== "string"
                        ? URL.createObjectURL(image)
                        : image
                    }
                    alt={`Banner ${index + 1}`}
                    height={80}
                    width={80}
                    style={{ objectFit: "cover", borderRadius: 8 }}
                  />
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteBannerImage(index)}
                    sx={{ ml: 1 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Box mt={2}>
                <CustomButton variant="outlined" component="label" size="small">
                  Upload Banner Image
                  <input type="file" hidden onChange={handleImageUpload} />
                </CustomButton>
              </Box>
            </>
          )}
        </Box>

        {/* VIP Desktop Banners */}
        <Box mt={4}>
          <Typography variant="h6">VIP Desktop Banners</Typography>
          {vipDesktopBanners?.map((banner, index) => (
            <Box display="flex" alignItems="center" key={index} mt={2}>
              <img
                src={
                  typeof banner?.url !== "string"
                    ? URL.createObjectURL(banner?.url)
                    : banner?.url
                }
                alt={`VIP Desktop Banner ${index + 1}`}
                height={80}
                width={80}
                style={{ objectFit: "cover", borderRadius: 4 }}
              />
              <TextField
                label="Title"
                value={banner.title}
                onChange={(e) => {
                  const updatedBanners = [...vipDesktopBanners];
                  updatedBanners[index].title = e.target.value;
                  setVipDesktopBanners(updatedBanners);
                }}
                sx={{ ml: 2 }}
              />
              <IconButton
                color="error"
                onClick={() =>
                  handleDeleteVipImage(index, setVipDesktopBanners)
                }
                aria-label="delete"
                sx={{ ml: 2 }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Box mt={2}>
            <CustomButton variant="outlined" component="label" size="small">
              Upload VIP Desktop Banner
              <input
                type="file"
                hidden
                onChange={(e) => handleVipImageUpload(e, setVipDesktopBanners)}
              />
            </CustomButton>
          </Box>
        </Box>

        {/* VIP Mobile Banners */}
        <Box mt={4}>
          <Typography variant="h6">VIP Mobile Banners</Typography>
          {vipMobileBanners.map((banner, index) => (
            <Box display="flex" alignItems="center" key={index} mt={2}>
              <img
                src={
                  typeof banner?.url !== "string"
                    ? URL.createObjectURL(banner?.url)
                    : banner.urls
                }
                alt={`VIP Mobile Banner ${index + 1}`}
                height={80}
                width={80}
                style={{ objectFit: "cover", borderRadius: 4 }}
              />
              <TextField
                label="Title"
                value={banner.title}
                onChange={(e) => {
                  const updatedBanners = [...vipMobileBanners];
                  updatedBanners[index].title = e.target.value;
                  setVipMobileBanners(updatedBanners);
                }}
                sx={{ ml: 2 }}
              />
              <IconButton
                color="error"
                onClick={() => handleDeleteVipImage(index, setVipMobileBanners)}
                aria-label="delete"
                sx={{ ml: 2 }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Box mt={2}>
            <CustomButton variant="outlined" component="label" size="small">
              Upload VIP Mobile Banner
              <input
                type="file"
                hidden
                onChange={(e) => handleVipImageUpload(e, setVipMobileBanners)}
              />
            </CustomButton>
          </Box>
        </Box>

        {Object.keys(formData).map((section) => (
          <Box key={section} mt={3}>
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              {section}
              <IconButton
                color="error"
                onClick={() => handleDeleteSection(section)}
              >
                <DeleteIcon />
              </IconButton>
            </Typography>
            {Object.entries(formData[section] || {}).map(([field, value]) => (
              <TextField
                key={field}
                label={field}
                value={value || ""}
                onChange={(e) =>
                  handleFieldChange(section, field, e.target.value)
                }
                fullWidth
                sx={{ mt: 1 }}
              />
            ))}
            <Box display="flex" gap={2} mt={2}>
              <TextField
                label="New Field Name"
                value={newField[section]?.field || ""}
                onChange={(e) =>
                  setNewField((prevFields) => ({
                    ...prevFields,
                    [section]: {
                      ...prevFields[section],
                      field: e.target.value,
                    },
                  }))
                }
                fullWidth
              />
              <TextField
                label="Field Value"
                value={newField[section]?.value || ""}
                onChange={(e) =>
                  setNewField((prevFields) => ({
                    ...prevFields,
                    [section]: {
                      ...prevFields[section],
                      value: e.target.value,
                    },
                  }))
                }
                fullWidth
              />
              <CustomButton
                variant="outlined"
                onClick={() => handleAddField(section)}
                sx={{ mt: 1 }}
              >
                Add Field
              </CustomButton>
            </Box>
          </Box>
        ))}
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <CustomButton onClick={onClose} variant="text" color="inherit">
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave} variant="contained" color="primary">
          {configData ? "Update" : "Create"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigDataModal;
