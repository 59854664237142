import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateAdmin($input: UserInput!) {
    CreateAdmin(input: $input) {
      message
      statusCode
    }
  }
`;

// Mutation for updating a user
export const UPDATE_USER = gql`
  mutation UpdateUser($_id: ID!, $input: UserUpdateInput!) {
    updateUser(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        firstName
        lastName
        email
        coverImage
        profileImage
        mobileNumber
        role
        profession
        pinCode
        verified
        isVerified
        isBlocked
        websiteUpdates
        businessUpdates
      }
    }
  }
`;

// Mutation for deleting a user
export const DELETE_USER = gql`
  mutation DeleteUser($_id: ID!) {
    deleteUser(_id: $_id) {
      statusCode
      message
    }
  }
`;

export const SIGN_IN_MUTATION = gql`
  mutation SignInByAdmin($input: SignInUserInput!) {
    signInByAdmin(input: $input) {
      accessToken
      message
      statusCode
      user {
        _id
        firstName
        lastName
        email
        role
      }
    }
  }
`;

export const ADD_PINCODE_MUTATION = gql`
  mutation AddPinCode($input: PinCodeInput!) {
    addPinCode(input: $input) {
      message
      statusCode
      data {
        _id
        areaName
        pinCode
      }
    }
  }
`;

export const DELETE_PINCODE_MUTATION = gql`
  mutation DeletePinCode($id: ID!) {
    deletePinCode(_id: $id) {
      message
      statusCode
      data {
        _id
        areaName
        pinCode
      }
    }
  }
`;

export const UPDATE_PINCODE = gql`
  mutation UpdatePinCode($_id: ID!, $input: UpdatePinCodeInput!) {
    updatePinCode(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        areaName
        pinCode
      }
    }
  }
`;

export const CREATE_EVENTS = gql`
  mutation AddEvent($input: EventInput!) {
    addEvent(input: $input) {
      message
      statusCode
      data {
        _id
        title
        price
      }
    }
  }
`;

// Mutation for updating a user
export const UPDATE_EVENTS = gql`
  mutation UpdateEvent($_id: ID!, $input: UpdateEventInput!) {
    updateEvent(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        title
        price
      }
    }
  }
`;

// Mutation for deleting a events
export const DELETE_EVENTS = gql`
  mutation DeleteEvent($_id: ID!) {
    deleteEvent(_id: $_id) {
      message
      statusCode
      data {
        _id
        title
        price
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation AddCategory($input: CategoryInput!) {
    addCategory(input: $input) {
      message
      statusCode
      data {
        _id
        categoryName
        imageUrl
        description
        categoryType
      }
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($file: Upload!) {
    uploadImage(file: $file) {
      encoding
      url
      filename
      mimetype
    }
  }
`;

export const CREATE_SUB_CATEGORY = gql`
  mutation AddSubCategory($input: SubCategoryInput!) {
    addSubCategory(input: $input) {
      message
      statusCode
    }
  }
`;

// Mutation for updating a user
export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $input: UpdateCategoryInput!) {
    updateCategory(_id: $id, input: $input) {
      message
      statusCode
      data {
        _id
        categoryName
      }
    }
  }
`;

// Mutation for deleting a user
export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(_id: $id) {
      message
      statusCode
      data {
        _id
        categoryName
        imageUrl
        description
        categoryType
      }
    }
  }
`;

export const CREATE_NEWS = gql`
  mutation CreateNews($input: AddEventsInput!) {
    addNews(input: $input) {
      message
      statusCode
      news {
        _id
        title
        date
        category
        sourceType
      }
    }
  }
`;

// Mutation for updating a user
export const UPDATE_NEWS = gql`
  mutation UpdateNews($id: ID!, $input: NewsInput!) {
    updateNews(id: $id, input: $input) {
      message
      statusCode
      news {
        _id
        title
        date
        category
        sourceType
      }
    }
  }
`;

// Mutation for deleting a user
export const DELETE__NEWS = gql`
  mutation DeleteNews($id: ID!) {
    deleteNews(_id: $id) {
      message
      statusCode
      news {
        _id
        title
        date
        category
        sourceType
      }
    }
  }
`;

export const CREATE_fAQ = gql`
  mutation AddFaq($input: FaqInput!) {
    addFaq(input: $input) {
      message
      statusCode
      data {
        _id
        question
        answer
      }
    }
  }
`;

// Mutation for updating a faq
export const UPDATE_fAQ = gql`
  mutation UpdateFaq($id: ID!, $input: UpdateFaqInput!) {
    updateFaq(_id: $id, input: $input) {
      message
      statusCode
      data {
        _id
        question
        answer
      }
    }
  }
`;

// Mutation for deleting a faq
export const DELETE_fAQ = gql`
  mutation DeleteFaq($id: ID!) {
    deleteFaq(_id: $id) {
      message
      statusCode
      data {
        _id
        question
        answer
      }
    }
  }
`;

export const CREATE_BLOG = gql`
  mutation AddBlog($input: AddBlogInput!) {
    addBlog(input: $input) {
      message
      statusCode
      data {
        _id
        publishedDate
        publishedBy
        description
        createdBy
      }
    }
  }
`;

// Mutation for updating a blog
export const UPDATE_BLOG = gql`
  mutation UpdateBlog($_id: ID, $input: UpdateBlogInput!) {
    updateBlog(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        title
        image
        publishedDate
        publishedBy
        description
        createdBy
        subCategoryId
        blogTitleReadingTime
        blogDescriptionReadingTime
      }
    }
  }
`;

// Mutation for deleting a blog
export const DELETE_BLOG = gql`
  mutation DeleteBlog($_id: ID!) {
    deleteBlog(_id: $_id) {
      message
      statusCode
      data {
        _id
        publishedDate
        publishedBy
        description
        createdBy
      }
    }
  }
`;

// Mutation for deleting a deal
export const DELETE_DEAL = gql`
  mutation DeleteDeals($_id: ID!) {
    deleteDeals(_id: $_id) {
      message
      statusCode
      data {
        _id
        title
      }
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation AddCategory($input: CategoryInput!) {
    addCategory(input: $input) {
      message
      statusCode
      data {
        _id
        categoryName
        imageUrl
        description
        categoryType
      }
    }
  }
`;

export const CREATE_BUSINESS = gql`
  mutation AddBusinessProfile($input: AddBusinessProfileInput!) {
    addBusinessProfile(input: $input) {
      message
      statusCode
      data {
        _id
        userId
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        verifiedBy
        verificationDate
        totalReviewsCount
        averageRating
      }
    }
  }
`;

export const ADD_SUB_CATEGORY = gql`
  mutation AddSubCategory($input: SubCategoryInput!) {
    addSubCategory(input: $input) {
      message
      statusCode
      data {
        _id
        subCategoryName
        imageUrl
        description
        metaTitle
        metaDescription
        metaKeywords
      }
    }
  }
`;
// Mutation for updating a BUSINESS
export const UPDATE_BUSINESS = gql`
  mutation updateBusinessProfileDetails(
    $id: ID!
    $input: UpdateBusinessProfileInputs!
  ) {
    updateBusinessProfileDetails(_id: $id, input: $input) {
      message
      statusCode
      data {
        _id
        userId
        UserName
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        reason
        verifiedBy
        verificationDate
        totalReviewsCount
        averageRating
        businessType
        deleteFlag
        claimBusiness
        yearOfEst
        businessStatus
      }
    }
  }
`;

export const DELETE_SUB_CATEGORY = gql`
  mutation DeleteSubCategory($_id: ID!) {
    deleteSubCategory(_id: $_id) {
      message
      statusCode
      data {
        _id
        subCategoryName
        imageUrl
        description
        metaTitle
        metaDescription
        metaKeywords
      }
    }
  }
`;
// Mutation for deleting a BUSINESS
export const DELETE_BUSINESS = gql`
  mutation DeleteBusinessProfile($_id: ID!) {
    deleteBusinessProfile(_id: $_id) {
      message
      statusCode
      data {
        _id
        userId
        UserName
        businessName
      }
    }
  }
`;

export const UPDATE_SUB_CATEGORY = gql`
  mutation UpdateSubCategory($_id: ID!, $input: SubCategoryUpdateInput!) {
    updateSubCategory(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        subCategoryName
        imageUrl
        description
        metaTitle
        metaDescription
        metaKeywords
      }
    }
  }
`;

export const ADD_TOP_BUSINESS = gql`
  mutation AddTopBusiness($input: TopBusinessInput!) {
    addTopBusiness(input: $input) {
      message
      statusCode
      data {
        _id
        businessProfileId
      }
    }
  }
`;
// Mutation for updating a deal

export const DELETE_TOP_BUSINESS = gql`
  mutation DeleteTopBusiness($_id: ID!) {
    deleteTopBusiness(_id: $_id) {
      message
      statusCode
      data {
        _id
        businessProfileId
        subCategoryId
        type
        rank
        title
        description
      }
    }
  }
`;

export const UPDATE_TOP_BUSINESS = gql`
  mutation UpdateTopBusiness($_id: ID!, $input: UpdateTopBusinessInput!) {
    updateTopBusiness(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        businessProfileId
        subCategoryId
        type
        rank
        title
        description
      }
    }
  }
`;

export const ADD_FAQ = gql`
  mutation AddFaq($input: FaqInput!) {
    addFaq(input: $input) {
      message
      statusCode
      data {
        _id
        businessProfileId
        subCategoryId
        question
        answer
      }
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation UpdateFaq($_id: ID!, $input: UpdateFaqInput!) {
    updateFaq(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        businessProfileId
        subCategoryId
        question
        answer
      }
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation DeleteFaq($_id: ID!) {
    deleteFaq(_id: $_id) {
      message
      statusCode
      data {
        _id
        businessProfileId
        subCategoryId
        question
        answer
      }
    }
  }
`;
// Mutation for deleting a deal

export const ADD_BLOG = gql`
  mutation AddBlog($input: BlogInput) {
    addBlog(input: $input) {
      message
      statusCode
      data {
        _id
        title
        image
        publishedDate
        publishedBy
        description
        createdBy
        subCategoryId
        blogTitleReadingTime
        blogDescriptionReadingTime
      }
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation DeleteReview($_id: ID!) {
    deleteReview(_id: $_id) {
      message
      statusCode
      data {
        _id
        businessProfileId
        categoryName
        ratings
        image
        video
        title
        description
        authorName
        authorPosition
        profileImage
        city
        createdAt
      }
    }
  }
`;

export const CREATE_POPULAR_LOCATIONS = gql`
  mutation AddPopularLocation($input: AddPopularLocation!) {
    addPopularLocation(input: $input) {
      message
      statusCode
      data {
        _id
        title
        address
        google_location_link
        category
      }
    }
  }
`;
export const UPDATE_POPULAR_LOCATIONS = gql`
  mutation UpdatePopularLocation($_id: ID!, $input: UpdatePopularLocation!) {
    updatePopularLocation(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        title
        address
        google_location_link
        category
      }
    }
  }
`;
export const DELETE_POPULAR_LOCATIONS = gql`
  mutation DeletePopularLocation($id: ID!) {
    deletePopularLocation(id: $id) {
      message
      statusCode
      data {
        _id
        title
        address
        google_location_link
        images
        about
        category
      }
    }
  }
`;

export const CREATE_ADVERTISE = gql`
  mutation AddAdvertise($input: AddAdvertiseInput!) {
    addAddAdvertise(input: $input) {
      message
      statusCode
      data {
        _id
        userId
        email
        phoneNumber
        image
        address
        description
        fullName
        companyName
      }
    }
  }
`;
export const UPDATE_ADVERTISE = gql`
  mutation UpdateAdvertise($id: ID!, $input: AdvertiseInput!) {
    UpdateAdvertise(id: $id, input: $input) {
      message
      statusCode
      data {
        _id
        userId
        email
        phoneNumber
        image
        address
        description
        fullName
        companyName
      }
    }
  }
`;
export const DELETE_ADVERTISE = gql`
  mutation DeleteAdsWithUs($id: ID!) {
    deleteAdsWithUs(id: $id) {
      message
      statusCode
    }
  }
`;

export const CREATE_BUSINESS_PROFILE = gql`
  mutation CreateBusinessProfile($input: CreateBusinessProfileInput!) {
    createBusinessProfile(input: $input) {
      message
      statusCode
      data {
        _id

        businessName
      }
    }
  }
`;

export const UPDATE_BUSINESS_PROFILE = gql`
  mutation UpdateBusinessProfileDetails(
    $_id: ID!
    $input: UpdateBusinessProfileInputs!
  ) {
    updateBusinessProfileDetails(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        userId
        businessName
      }
    }
  }
`;

export const SAVE_SELECTED_CATEGORY = gql`
  mutation SaveSelectedCategory($input: SaveSelectedCategoryInput) {
    saveSelectedCategory(input: $input) {
      success
      message
    }
  }
`;

export const ADD_TAG = gql`
  mutation AddTag($input: TagsInput!) {
    addTag(input: $input) {
      message
      statusCode
      data {
        _id
        key
        value
        subCategoryId
        clickCount
      }
    }
  }
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($_id: ID!) {
    deleteTag(_id: $_id) {
      message
      statusCode
      data {
        _id
        key
        value
        subCategoryId
        clickCount
      }
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation UpdateTag($_id: ID!, $input: UpdateTagsInput!) {
    updateTag(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        key
        value
        subCategoryId
        clickCount
      }
    }
  }
`;

export const DELETE_HOME = gql`
  mutation DeleteSavedSelectedCategory($_id: ID!) {
    deleteSavedSelectedCategory(_id: $_id) {
      success
      message
    }
  }
`;

export const UPDATE_HOME = gql`
  mutation UpdateSavedSelectedCategory($input: UpdateSavedCategoryInput!) {
    updateSavedSelectedCategory(input: $input) {
      success
      message
    }
  }
`;

export const VERIFY_BUSINESS = gql`
  mutation VerifyBusinessProfile($input: VerifyBusinessProfileInput!) {
    verifyBusinessProfile(input: $input) {
      message
      statusCode
      data {
        _id
        userId
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        verifiedBy
        verificationDate
        totalReviewsCount
        averageRating
      }
    }
  }
`;
export const VERIFY_EVENTS = gql`
  mutation VerifyEvent($input: VerifyEventInput!) {
    verifyEvent(input: $input) {
      message
      statusCode
      data {
        _id
        title
      }
    }
  }
`;

export const CREATE_DEAL = gql`
  mutation AddDeals($input: DealsInput!) {
    addDeals(input: $input) {
      message
      statusCode
      data {
        _id
        businessProfileId
        title
        image
        category
        subCategoryId
        area
        offer
        offerType
        description
        termsAndConditions
      }
    }
  }
`;
export const UPDATE_DEAL = gql`
  mutation UpdateDeals($_id: ID!, $input: UpdateDealsInput!) {
    updateDeals(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        businessProfileId
        title
        image
        category
        subCategoryId
        area
        offer
        offerType
        description
        termsAndConditions
      }
    }
  }
`;

export const UPLOAD_EXCEL_BUSINESS = gql`
  mutation UploadBusinessProfiles($file: Upload!) {
    uploadBusinessProfiles(file: $file) {
      errors {
        data
        message
      }
      message
      statusCode
      successfullyUploadedBusinesses
      errorUploadedBusinesses
    }
  }
`;

export const UPDATE_POPULAR_LOCATION_RANK = gql`
  mutation UpdatePopularLocationRanksBulk(
    $input: UpdatePopularLocationRankInput!
  ) {
    updatePopularLocationRanksBulk(input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_BUSINESS_RANK = gql`
  mutation UpdateBusinessProfilesRanksBulk($input: UpdateBusinessRankInput!) {
    updateBusinessProfilesRanksBulk(input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_BLOG_RANK = gql`
  mutation UpdateBlogsRanksBulk($input: UpdateBlogsRankInput!) {
    updateBlogsRanksBulk(input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_EVENT_RANK = gql`
  mutation UpdateEventsRanksBulk($input: UpdateEventsRankInput!) {
    updateEventsRanksBulk(input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_PINCODE_RANK = gql`
  mutation UpdatePinCodesRanksBulk($input: UpdatePinCodesRankInput!) {
    updatePinCodesRanksBulk(input: $input) {
      message
      statusCode
    }
  }
`;

export const UPDATE_CATEGORY_RANK = gql`
  mutation UpdateCategoryRanksBulk($input: UpdateCategorysRankInput!) {
    updateCategoryRanksBulk(input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_SUB_CATEGORY_RANK = gql`
  mutation UpdateSubCategoriesRanksBulk($input: UpdateSubCategoriesRankInput!) {
    updateSubCategoriesRanksBulk(input: $input) {
      message
      statusCode
    }
  }
`;

export const UPDATE_HOME_SAVE_CATEGORY_RANK = gql`
  mutation UpdateSavedCategoryRanksBulk($input: UpdateSavedCategoryRankInput!) {
    updateSavedCategoryRanksBulk(input: $input) {
      message
    }
  }
`;
export const VERIFY_BLOG = gql`
  mutation VerifyBlog($input: VerifyBlogInput!) {
    verifyBlog(input: $input) {
      message
      statusCode
      data {
        _id
        title
      }
    }
  }
`;
export const VERIFY_BUSINESS_BY_US = gql`
  mutation VerifyBusinessProfileByUs($input: VerifyBusinessProfileByUsInput!) {
    verifyBusinessProfileByUs(input: $input) {
      message
      statusCode
      data {
        _id
        UserName
        businessName
        brandName
      }
    }
  }
`;
export const RESTORE_USER = gql`
  mutation RestoreUser($_id: ID!) {
    restoreUser(_id: $_id) {
      accessToken
      message
      statusCode
      data {
        _id
        email
      }
    }
  }
`;
export const ADD_DEALS_ENUM = gql`
  mutation AddDealsEnum($input: DealsEnumInput!) {
    addDealsEnum(input: $input) {
      message
      statusCode
      data {
        _id
        name
        description
      }
    }
  }
`;
export const ADD_EVENTS_ENUM = gql`
  mutation AddEventsEnum($input: EventsEnumInput!) {
    addEventsEnum(input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_DEALS_ENUM = gql`
  mutation UpdateDealsEnum($input: DealsEnumInput!, $_id: ID!) {
    updateDealsEnum(_id: $_id, input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_EVENTS_ENUM = gql`
  mutation UpdateEventsEnum($input: EventsEnumInput!, $_id: ID!) {
    updateEventsEnum(_id: $_id, input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_CLAIM_STATUS = gql`
  mutation UpdateClaimBusinessStatus(
    $_id: ID!
    $claimStatus: ClaimStatus!
    $reason: String
  ) {
    updateClaimBusinessStatus(
      _id: $_id
      claimStatus: $claimStatus
      reason: $reason
    ) {
      message
      statusCode
    }
  }
`;
export const DELETE_CLAIM_STATUS = gql`
  mutation DeleteClaimBusiness($_id: ID!) {
    deleteClaimBusiness(_id: $_id) {
      message
      statusCode
    }
  }
`;
export const CREATE_CONFIG_DATA = gql`
  mutation AddConfigData($input: ConfigDataInput!) {
    addConfigData(input: $input) {
      message
      statusCode
      data {
        _id
        configData
      }
    }
  }
`;
export const UPDATE_CONFIG_DATA = gql`
  mutation UpdateConfigData($_id: ID!, $input: updateConfigDataInput!) {
    updateConfigData(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        configData
      }
    }
  }
`;
export const ADD_POPULAR_LOCATION_ENUM = gql`
  mutation AddPopularLocationEnum($input: PopularLocationEnumInput!) {
    addPopularLocationEnum(input: $input) {
      message
      statusCode
      data {
        _id
        name
        description
      }
    }
  }
`;
export const UPDATE_POPULAR_LOCATION_ENUM = gql`
  mutation UpdatePopularLocationEnum(
    $_id: ID!
    $input: PopularLocationEnumInput!
  ) {
    updatePopularLocationEnum(_id: $_id, input: $input) {
      message
      statusCode
      data {
        _id
        name
        description
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePasswordByAdmin($_id: ID!, $newPassword: TString!) {
    updatePasswordByAdmin(_id: $_id, newPassword: $newPassword) {
      message
      statusCode
    }
  }
`;
