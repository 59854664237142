import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import { Deal } from "./types";
import CustomButton from "src/components/CustomButton";

interface DealCardProps {
  deal: Deal;
  onViewDetails: (deal: Deal) => void;
}

const DealCard: React.FC<DealCardProps> = ({ deal, onViewDetails }) => {
  return (
    <Card
      sx={{
        width: 300,
        height: "auto",
        margin: 2,
        boxShadow: 3,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.05)",
        },
        borderRadius: 8,
      }}
    >
      <CardMedia
        component="img"
        height={140}
        image={deal.image}
        alt={deal.title}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          {deal.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Category:</strong> {deal.category}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Area:</strong> {deal.area}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Offer:</strong> {deal.offer} ({deal.offerType})
        </Typography>
        {deal.activityStatus && (
          <Chip
            label={deal.activityStatus}
            color={deal.activityStatus === "Active" ? "success" : "warning"}
            sx={{ mt: 1 }}
          />
        )}
        <Box mt={2}>
          <CustomButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => onViewDetails(deal)}
            sx={{
              borderRadius: 5,
            }}
          >
            View Details
          </CustomButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DealCard;
