import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PincodeModal from "./PincodeModal";
import { GET_PINCODE_DETAIL, GET_PINCODES } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import { useDispatch } from "react-redux";
import { ACTION_DELETE_PINCODE } from "src/store/Action/PincodeActions/DeletePincodeAction";
import { toast } from "react-toastify";
import DeleteModel from "src/components/Common/DeleteModel";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import SaveIcon from "@mui/icons-material/Save";
import { UPDATE_PINCODE_RANK } from "src/graphql/mutations";
import { ClearIcon } from "@mui/x-date-pickers";
import {
  DELETE_All_PINCODES,
  DELETE_MULTIPLE_PINCODES,
} from "src/graphql/DeleteMutation";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getRole } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";
interface Pincode {
  _id: string;
  areaName: string;
  pinCode: string;
}

const Pincode: React.FC = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState<Pincode[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [selectedPincode, setSelectedPincode] = useState<Pincode | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [isEditPincode, setIsEditPincode] = useState(false);
  const initialPinCodeObj = {
    areaName: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    imageUrl: "",
    pinCode: [] as any,
  };

  const [pinCodeObj, setPinCodeObj] = useState<any>(initialPinCodeObj);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [UpdatePinCodesRanksBulk] = useMutation(UPDATE_PINCODE_RANK);
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [DeleteMultiplePinCodes] = useMutation(DELETE_MULTIPLE_PINCODES);
  const [DeleteAllPinCodes] = useMutation(DELETE_All_PINCODES);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";

  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
          },
        ]
      : []),
    { headerName: "Area Name", field: "areaName", flex: 1 },
    {
      headerName: "PinCodes",
      field: "pinCode",
      flex: 1,
      renderCell: ({ row }: { row: Pincode }) => (
        <Box>{row?.pinCode?.length}</Box>
      ),
    },
    {
      headerName: "Rank",
      field: "rank",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row.rank || "");

        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: Pincode }) => (
        <div>
          <IconButton
            onClick={() => handleEdit(row)}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(row)}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_PINCODES, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
    },
  });

  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultiplePinCodes({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultiplePinCodes?.statusCode === 200) {
        toast.success(res?.data?.deleteMultiplePinCodes?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultiplePinCodes?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllPinCodes();
      if (res?.data?.deleteAllPinCodes?.statusCode === 200) {
        toast.success(res?.data?.deleteAllPinCodes?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllPinCodes?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };

  useEffect(() => {
    if (data?.getPinCodes?.data) {
      setList(data.getPinCodes.data);
      setTotal(data.getPinCodes.count);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term.length > 0 ? term : "");
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const [fetchPincodeDetails] = useLazyQuery(GET_PINCODE_DETAIL);

  const handleEdit = async (rowData: any) => {
    try {
      if (!rowData?._id) {
        toast.error("Invalid row data");
        console.error("Invalid row data");
        return;
      }

      const res = await fetchPincodeDetails({
        variables: {
          _id: rowData._id,
        },
      });

      if (res?.data?.getPinCode?.data) {
        const { __typename, ...pincodeDetails } = res?.data?.getPinCode.data;
        setPinCodeObj(pincodeDetails);
        setOpenModal(true);
        setIsEditPincode(true);
      } else {
        toast.error("Failed to fetch pincode details");
        console.error("Failed to fetch pincode details");
      }
    } catch (error: any) {
      toast.error(error?.message);
      console.error("An error occurred while fetching pincode details:", error);
    }
  };

  const handleDelete = (rowData: Pincode) => {
    setSelectedPincode(rowData);
    setOpenConfirmDialog(true);
  };

  const confirmDelete = async () => {
    try {
      if (selectedPincode) {
        const res = await dispatch(ACTION_DELETE_PINCODE(selectedPincode._id));
        if (res?.type === "DELETE_PINCODE_ACTION_SUCCESS") {
          toast.success(
            res?.payload?.deletePinCode?.message ||
              "Pincode deleted successfully!"
          );
          refetch();
        } else {
          throw new Error(res?.payload);
        }
      }
    } catch (error: any) {
      toast.error(
        error?.message || "An error occurred while deleting the pincode."
      );
    } finally {
      setOpenConfirmDialog(false);
      setSelectedPincode(null);
    }
  };

  const handleCloseModal = () => {
    setPinCodeObj({
      areaName: "",
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      imageUrl: "",
      pinCode: [] as any,
    });
    setOpenModal(false);
    setIsEditPincode(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      pinCodeId: id,
      rank: rankUpdates[id],
    }));

    try {
      const response = await UpdatePinCodesRanksBulk({
        variables: { input: { pincodes: input } },
      });
      if (response?.data?.updatePinCodesRanksBulk?.statusCode === 200) {
        toast.success(response.data.updatePinCodesRanksBulk.message);
        setRankUpdates({});
      } else {
        throw new Error(response.data.updatePinCodesRanksBulk.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      refetch();
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      {!openModal && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              Pincode
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
              <Box width={200}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <ActionMenu
                isSuperAdmin={isSuperAdmin}
                setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
                setOpenDeleteAllModel={setOpenDeleteAllModel}
              />
              {isRankEdit && (
                <CustomButton
                  onClick={handleRankUpdate}
                  variant="contained"
                  sx={{ width: 50 }}
                >
                  <SaveIcon />
                </CustomButton>
              )}
              <Box>
                <CustomButton
                  onClick={() => setOpenModal(true)}
                  variant="contained"
                  className="width: 200px"
                >
                  Create
                </CustomButton>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              paginationModel={{
                page: pagination.page - 1,
                pageSize: pagination.pageSize,
              }}
              totalCount={total}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </Box>
        </>
      )}
      {openModal && (
        <PincodeModal
          handleClose={handleCloseModal}
          refetchPincodes={() => refetch()}
          initialPinCodeObj={initialPinCodeObj}
          pinCodeObj={pinCodeObj}
          setPinCodeObj={setPinCodeObj}
          isEditPincode={isEditPincode}
        />
      )}
      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this pincode?"
      />
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } pincodes?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all pincodes?"
        />
      )}
    </Box>
  );
};

export default Pincode;
