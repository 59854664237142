import { ApolloProvider } from "@apollo/client";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import client from "./apolloClient";
import AuthLayout from "./layout/AuthLayout";
import PrivateLayout from "./layout/PrivateLayout";
import RoutesList from "./routes";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "./components/Common/Utils";
import { ROUTES } from "./constant";
import NotFound from "./404";

const App = () => {
  const userDataSelector = useSelector((state: any) => state.authReducer);
  const token = getToken();
  const navigate = useNavigate();
  const user = userDataSelector.users;
  const renderRoutes = () => {
    const isLogin = !!user;

    const renderRoute = (
      Component: React.FC,
      layout: string,
      isPrivate: boolean
    ) => {
      if (Component) {
        switch (layout) {
          case "private":
            return isLogin && isPrivate ? (
              <PrivateLayout>
                <Component />
              </PrivateLayout>
            ) : (
              <Navigate to={ROUTES.EVENTS} />
            );
          case "auth":
          default:
            return (
              <AuthLayout>
                <Component />
              </AuthLayout>
            );
        }
      }
      return null;
    };

    return RoutesList.map((route: any) => (
      <Route
        key={route.name}
        path={route.path}
        element={renderRoute(route.component, route.layout, route.isPrivate)}
      />
    ));
  };
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      window.location.pathname === "/" && navigate(ROUTES.EVENTS);
    }
  }, []);

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Routes>
          {renderRoutes()}
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Navigate to="/events" replace />} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{
            zIndex: 999999999,
          }}
        />
      </ApolloProvider>
    </div>
  );
};

export default App;
