import { toast } from "react-toastify";
import Cookie from "js-cookie";
export interface PincodeOption {
  value: string;
  label: string;
}

export const getToken = () => {
  return Cookie.get("accessToken");
};

export const setToken = (token: string) => {
  return Cookie.set("accessToken", token, {
    secure: true,
    sameSite: "Strict",
    expires: 1,
  });
};

export const removeToken = () => {
  return Cookie.remove("accessToken");
};

export const getRole = () => {
  return Cookie.get("role");
};
export const removeRole = () => {
  return Cookie.remove("role");
};
const url = process.env.REACT_APP_GRAPHQL_ENDPOINT ?? "";

// Reusable function to handle image uploads (single or multiple)
const uploadToServer = async (formData: FormData, operationName: string) => {
  try {
    const token = getToken()?.replace(/^"|"$/g, "");
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        "x-apollo-operation-name": operationName,
        "apollo-require-preflight": "true",
        authorization: `Bearer ${token} `,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error(`Error in ${operationName}:`, error);
    toast.error(`Error uploading images for ${operationName}.`);
    throw error;
  }
};

// For multiple image upload
export const uploadImages = async (
  imagesData: File[],
  folder: string,
  id: string
) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($files: [Upload!]!, $folder: String, $id: String) {
          uploadMultipleImages(files: $files, folder: $folder, id: $id) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        files: new Array(imagesData.length).fill(null),
        folder: folder,
        id: id,
      },
    })
  );

  const map: { [key: string]: string[] } = {};
  imagesData.forEach((_, index) => {
    map[index.toString()] = [`variables.files.${index}`];
  });

  formData.append("map", JSON.stringify(map));

  imagesData.forEach((image, index) => {
    formData.append(index.toString(), image);
  });

  return await uploadToServer(formData, "uploadMultipleImages");
};

// For single image upload
export const uploadImage = async (
  imageData: File,
  folder: string,
  id: string
) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($file: Upload!, $folder: String, $id: String) {
          uploadImage(file: $file,folder: $folder, id: $id) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        file: null,
        folder: folder,
        id: id,
      },
    })
  );
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", imageData);

  return await uploadToServer(formData, "uploadImage");
};

export const uploadFile = async (videoData: File) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($file: Upload!) {
          uploadFile(file: $file) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        file: null,
      },
    })
  );
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", videoData);

  return await uploadToServer(formData, "uploadFile");
};

export const uploadExcel = async (excelData: File) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
       mutation UploadOrUpdateBusinesses($file:Upload!) {
    uploadOrUpdateBusinesses(file: $file) {
        message
        statusCode
        successfullyUploadedBusinesses
        errorUploadedBusinesses
        errors {
            data
            message
        }
    }
}
      `,
      variables: {
        file: null,
      },
    })
  );

  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", excelData);

  return await uploadToServer(formData, "uploadBusinessProfiles");
};
export const uploadImageExcel = async (excelData: File) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
             mutation UploadBusinessProfilesGallery($file:Upload!) {
             uploadBusinessProfilesGallery(file: $file) {
                      message
                      statusCode
                     successfullyUploadedBusinesses
                     errorUploadedBusinesses
                     errors {
                      data
                      message
                    }
                }
            }  `,
      variables: {
        file: null,
      },
    })
  );

  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", excelData);

  return await uploadToServer(formData, "uploadBusinessProfilesGallery");
};

export const uploadSubCategoryExcel = async (
  excelData: File,
  categoryId?: string
) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation UploadSubCategory($file: Upload!, $categoryId: ID) {
          uploadSubCategory(file: $file, categoryId: $categoryId) {
            message
            statusCode
          }
        }
      `,
      variables: {
        file: null,
        categoryId: categoryId,
      },
    })
  );

  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", excelData);

  if (categoryId) {
    formData.append("variables.categoryId", categoryId);
  }

  return await uploadToServer(formData, "uploadSubCategory");
};
