import { Switch, FormControlLabel, Box } from "@mui/material";

function ToggleSwitch({ mode, setMode }: any) {
  const handleToggle = (event: any) => {
    setMode(event.target.checked);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch checked={mode} onChange={handleToggle} color="primary" />
        }
        label="Rank"
      />
    </Box>
  );
}

export default ToggleSwitch;
