import React from "react";
import {
  DataGrid,
  GridPaginationModel,
  GridRowSelectionModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { Box, Typography, useTheme } from "@mui/material";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  width: "100%", // Ensure the grid takes the full width of the parent
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#f4f6f8",
    color: "#197BA0",
    fontWeight: 600,
  },
  "& .MuiDataGrid-cell": {
    padding: theme.spacing(1),
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#197ba017", // Light hover effect
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "transparent", // Make selected row background transparent
    outline: "none",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #e0e0e0", // Add border to footer
  },
  "& .MuiDataGrid-pagination": {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
  },
}));

interface CustomTableProps<R> {
  columns: any[];
  data: GridRowsProp<any>;
  paginationModel: GridPaginationModel;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  totalCount: number;
  onRowClick?: (id: string) => void;
}

const CustomTable = <R,>({
  columns,
  data,
  totalCount,
  paginationModel,
  onPageChange,
  onPageSizeChange,
  onRowClick,
}: CustomTableProps<R>) => {
  const theme = useTheme(); // Use theme for responsive design

  const currentPage = paginationModel.page || 0;
  const pageSize = paginationModel.pageSize || 25;

  const updatedColumns = [
    {
      headerName: "Index",
      field: "index",
      flex: 0.5,
      renderCell: ({ row }: any) => {
        const index = currentPage * pageSize + (data.indexOf(row) + 1);
        return (
          <Box mt={2}>
            <Typography variant="body2">{index}</Typography>
          </Box>
        );
      },
    },
    ...columns,
  ];

  return (
    <div style={{ width: "100%", boxSizing: "border-box" }}>
      <CustomDataGrid
        rows={data as any}
        columns={updatedColumns}
        getRowId={(row) => row._id}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => {
          if (model.page !== undefined) {
            onPageChange(model.page);
          }
          if (model.pageSize !== undefined) {
            onPageSizeChange(model.pageSize);
          }
        }}
        pageSizeOptions={[25, 50, 100, 200, 300, 400, 500]}
        rowCount={totalCount}
        onRowClick={(params) => {
          if (onRowClick && params.row._id) {
            onRowClick(params.row._id);
          }
        }}
        checkboxSelection={false}
        isRowSelectable={() => false}
        rowSelectionModel={[] as GridRowSelectionModel}
        autoHeight
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      />
    </div>
  );
};

export default CustomTable;
