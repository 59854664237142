import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Box,
  Typography,
  FormControl,
  Grid,
  Container,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_EVENTS, UPDATE_EVENTS } from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import Select from "react-select";
import { toast } from "react-toastify";
import { eventCategoryOptions, languageOptions } from "./Events";
import AddressInput from "src/components/Common/AddressInput";
import { uploadFile, uploadImage } from "src/components/Common/Utils";
import moment from "moment";
import { useFormik as useFormic } from "formik";
import backButton from "../../Images/backButton.svg";
import { EventsPageProps, validationSchema } from "./Events";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { GET_EVENTS_ENUM_ALL } from "src/graphql/query";

const EventsPage: React.FC<EventsPageProps> = ({
  events,
  refetchEvents,
  handleClose,
}) => {
  const [formValues, setFormValues] = useState<any>({
    title: "",
    images: "",
    desktopImage: "",
    mobileImage: "",
    eventBy: "",
    description: "",
    startTime: "",
    endTime: "",
    type: "",
    price: [{ price: 0, priceTitle: "" }],
    venue: "",
    startDate: "",
    endDate: "",
    language: "",
    artist: "",
    bookingPartner: "",
    pincode: "",
    address: {
      address_1: "",
      address_2: "",
      pinCode: "",
      location: "",
      landmark: "",
    },
  });
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [AddEvent] = useMutation(CREATE_EVENTS);
  const [UpdateEvent] = useMutation(UPDATE_EVENTS);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const mobileImageRef = useRef<HTMLInputElement | null>(null);
  const desktopImageRef = useRef<HTMLInputElement | null>(null);
  const formic = useFormic({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => handleSubmit,
  });
  const [mobileUrl, setMobileUrl] = useState<string>("");
  const [desktopUrl, setDesktopUrl] = useState<string>("");
  const [galleryUrl, setGalleryUrl] = useState<string>("");
  const [useUrl, setUseUrl] = useState<boolean>(true);
  const [eventCategories, setEventCategories] = useState<any>([]);

  useEffect(() => {
    if (events) {
      setFormValues({
        title: events?.title || "",
        images: events?.images || [],
        mobileImage: events?.mobileImage || "",
        desktopImage: events?.desktopImage || "",
        eventBy: events?.eventBy || "",
        description: events?.description || "",
        startTime: events?.startTime || "",
        endTime: events?.endTime || "",
        type: events?.type || "",
        price: events?.price || [{ price: 0, priceTitle: "" }],
        venue: events?.venue || "",
        startDate: events?.startDate
          ? moment(events.startDate).format("YYYY-MM-DD")
          : "",
        endDate: events?.endDate
          ? moment(events.endDate).format("YYYY-MM-DD")
          : "",
        language: events?.language || "",
        artist: events?.artist || "",
        address: events?.address[0] || "",
        bookingPartner: events?.bookingPartner || "",
        pincode: events?.pincode || "",
      });
    }
  }, [events]);

  const { data, error } = useQuery(GET_EVENTS_ENUM_ALL, {
    onError: (err) => {
      toast.error("Facing issue in fetching categories");
      console.error("Query error:", err);
    },
    onCompleted: (data) => {
      const eventCategories = data?.getAllEventsEnum?.data ?? [];
      const optionObj = eventCategories?.map((item: any) => ({
        value: item?.name,
        label: item?.name,
      }));
      setEventCategories(optionObj);
      console.log("Mapped options:", optionObj);
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formic.handleChange(e);
    const { name, value } = e.target;
    setFormValues((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files?.[0];
      const fileUrl = URL.createObjectURL(file);

      setFormValues((prev: any) => ({
        ...prev,
        [type]: file,
      }));
    }
  };

  const handleMultipleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const newFiles = Array.from(files).map((file: any) => file);

      setFormValues((prev: any) => ({
        ...prev,
        images: [...prev.images, ...newFiles],
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const uploadedImages: string[] = [];
      await validationSchema.validate({ ...formValues }, { abortEarly: false });
      for (const fileUrl of formValues.images) {
        if (typeof fileUrl === "string") {
          uploadedImages?.push(fileUrl);
        } else {
          if (fileUrl.type.includes("video")) {
            const response = await uploadFile(fileUrl);
            if (response?.uploadFile?.url) {
              uploadedImages?.push(response?.uploadFile.url);
            }
          } else {
            const response = await uploadImage(
              fileUrl,
              "events",
              formValues?.title
            );
            if (response?.uploadImage?.url) {
              uploadedImages?.push(response.uploadImage.url);
            }
          }
        }
      }

      if (typeof formValues?.mobileImage !== "string") {
        const response = await uploadImage(
          formValues?.mobileImage,
          "events",
          formValues?.title
        );
        if (response?.uploadImage?.url) {
          formValues.mobileImage = response?.uploadImage?.url;
        }
      }

      if (typeof formValues?.desktopImage !== "string") {
        const response = await uploadImage(
          formValues?.desktopImage,
          "events",
          formValues?.title
        );
        if (response?.uploadImage?.url) {
          formValues.desktopImage = response?.uploadImage?.url;
        }
      }

      // Submit form with uploaded image URLs

      const address = formValues?.address as any;
      if (address && address.__typename) {
        const { __typename, ...addressWithoutTypename } = address;
        formValues.address = addressWithoutTypename;
      }

      const input = { ...formValues, images: uploadedImages };

      // await validationSchema.validate(input, { abortEarly: true });

      const response = events
        ? await UpdateEvent({
            variables: {
              _id: events._id,
              input,
            },
          })
        : await AddEvent({ variables: { input } });

      if (
        response?.data?.addEvent?.statusCode === 200 ||
        response?.data?.updateEvent?.statusCode === 200
      ) {
        toast.success(
          response.data[events ? "updateEvent" : "addEvent"].message ||
            `Event ${events ? "updated" : "created"} successfully!`
        );

        refetchEvents();
        handleClose();
      } else {
        throw new Error(
          response?.data?.addEvent?.message ||
            response?.data?.updateEvent?.message
        );
      }
    } catch (error: any) {
      if (error.inner) {
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error(error?.message || "An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePriceChange = (index: number, event: any) => {
    const { name, value } = event.target;

    let newPrices = [...formValues?.price];
    newPrices[index] = {
      ...newPrices[index],
      [name]: value,
    };
    setFormValues((prev: any) => ({
      ...prev,
      price: newPrices,
    }));
  };

  const handleAddPrice = () => {
    const newPrices = [...formValues.price, { price: 0, priceTitle: "" }];
    setFormValues((prev: any) => ({
      ...prev,
      price: newPrices,
    }));
  };

  const handleAddUrl = () => {
    if (galleryUrl.startsWith("https")) {
      setFormValues((prev: any) => ({
        ...prev,
        images: [...prev.images, galleryUrl],
      }));
      setGalleryUrl("");
    }
  };
  const handleRemovePrice = (index: number) => {
    const newPrices = formValues?.price.filter(
      (_: any, i: number) => i !== index
    );
    setFormValues((prev: any) => ({
      ...prev,
      price: newPrices,
    }));
  };
  return (
    <Container>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={handleClose}
        />
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {events ? "Update Event" : "Create Event"}
        </Typography>
      </Box>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="title"
              margin="dense"
              label="Title"
              fullWidth
              required
              value={formValues.title}
              onChange={handleInputChange}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="description"
              margin="dense"
              label="Description"
              fullWidth
              value={formValues.description}
              onChange={handleInputChange}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box marginTop={2}>
              <TextField
                name="startDate"
                margin="dense"
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={formValues.startDate}
                onChange={handleInputChange}
                error={!!errors.startDate}
                helperText={errors.startDate}
                InputProps={{
                  inputProps: {
                    min: moment(new Date()).format("YYYY-MM-DD"),
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box marginTop={2}>
              <TextField
                name="endDate"
                margin="dense"
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={formValues.endDate}
                onChange={handleInputChange}
                error={!!errors.endDate}
                helperText={errors.endDate}
                InputProps={{
                  inputProps: {
                    min: moment(formValues.startDate).format("YYYY-MM-DD"),
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box marginTop={2}>
              <TextField
                name="startTime"
                margin="dense"
                label="Start Time"
                type="time"
                fullWidth
                value={formValues.startTime}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.startTime}
                helperText={errors.startTime}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box marginTop={2}>
              <TextField
                name="endTime"
                margin="dense"
                label="End Time"
                type="time"
                fullWidth
                value={formValues.endTime}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.endTime}
                helperText={errors.endTime}
              />
            </Box>
          </Grid>

          <Grid container item xs={12} sm={6} spacing={2}>
            {formValues?.price.map((priceField: any, index: number) => (
              <Grid item xs={12} sm={6} key={index}>
                <TextField
                  name="price"
                  label="Price"
                  margin="dense"
                  type="number"
                  fullWidth
                  value={priceField.price}
                  onChange={(e) => handlePriceChange(index, e)}
                  error={!!errors[`price${index}`]}
                  helperText={errors[`price${index}`]}
                />
                <TextField
                  name="priceTitle"
                  label="Price Title"
                  margin="dense"
                  fullWidth
                  value={priceField.priceTitle}
                  onChange={(e) => handlePriceChange(index, e)}
                  error={!!errors[`priceTitle${index}`]}
                  helperText={errors[`priceTitle${index}`]}
                />
                <IconButton
                  color="primary"
                  onClick={handleAddPrice}
                  disabled={formValues?.price?.length > 4}
                >
                  <AddCircleOutline />
                </IconButton>
                {formValues?.price?.length > 1 && (
                  <IconButton
                    color="secondary"
                    onClick={() => handleRemovePrice(index)}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="venue"
              margin="dense"
              label="Venue"
              fullWidth
              value={formValues.venue}
              onChange={handleInputChange}
              error={!!errors.venue}
              helperText={errors.venue}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box my={2}>
              <Typography variant="h6" gutterBottom>
                Language
              </Typography>
              <FormControl fullWidth sx={{ zIndex: "999999" }}>
                <Select
                  options={languageOptions}
                  value={languageOptions.find(
                    (option) => option.value === formValues?.language
                  )}
                  onChange={(data) =>
                    setFormValues((prev: any) => ({
                      ...prev,
                      language: data?.value,
                    }))
                  }
                  placeholder="Filter by Category"
                />
                {!!errors.type && (
                  <Typography
                    variant="subtitle2"
                    mt={1}
                    mx={2}
                    color={"#D32f2f"}
                  >
                    {errors.language}
                  </Typography>
                )}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box my={2}>
              <Typography variant="h6" gutterBottom>
                Type
              </Typography>
              <FormControl fullWidth sx={{ zIndex: "9999" }}>
                <Select
                  options={eventCategories}
                  value={eventCategories.find(
                    (option: any) => option?.value === formValues?.type
                  )}
                  onChange={(data) =>
                    setFormValues((prev: any) => ({
                      ...prev,
                      type: data?.value,
                    }))
                  }
                  placeholder="Filter by Category"
                />
                {!!errors.type && (
                  <Typography
                    variant="subtitle2"
                    mt={1}
                    mx={2}
                    color={"#D32f2f"}
                  >
                    {errors.type}
                  </Typography>
                )}
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            {
              <Box my={2}>
                <AddressInput
                  pincode={formValues.pincode}
                  address={formValues.address as any}
                  setAddress={setFormValues as any}
                  setValues={setFormValues}
                />
                {!!errors.address && (
                  <Typography
                    variant="subtitle2"
                    mt={1}
                    mx={2}
                    color={"#D32f2f"}
                  >
                    {errors.address}
                  </Typography>
                )}
              </Box>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="artist"
              margin="dense"
              label="Artist"
              fullWidth
              value={formValues.artist}
              onChange={handleInputChange}
              error={!!errors.artist}
              helperText={errors.artist}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="eventBy"
              margin="dense"
              label="Event By"
              fullWidth
              value={formValues.eventBy}
              onChange={handleInputChange}
              error={!!errors.eventBy}
              helperText={errors.eventBy}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="bookingPartner"
              margin="dense"
              label="Booking Partner"
              fullWidth
              value={formValues.bookingPartner}
              onChange={handleInputChange}
              error={!!errors.bookingPartner}
              helperText={errors.bookingPartner}
            />
          </Grid>

          {/* Mobile Image Upload */}
          <Grid item xs={12} sm={6} style={{ position: "relative" }}>
            <Typography variant="h6" gutterBottom>
              Mobile Image
            </Typography>
            {formValues.mobileImage ? (
              <Box
                position="relative"
                width="100%"
                height="auto"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <img
                    src={
                      typeof formValues.mobileImage === "string"
                        ? formValues.mobileImage
                        : URL.createObjectURL(formValues.mobileImage)
                    }
                    alt="Mobile Upload"
                    style={{
                      width: "100%", // Make it responsive
                      height: "auto",
                      maxHeight: "500px", // Limit height for larger screens
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <CustomButton
                  variant="outlined"
                  color="warning"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    zIndex: 1,
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                    minWidth: "0",
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    setFormValues((prev: any) => ({
                      ...prev,
                      mobileImage: "",
                    }));
                  }}
                  aria-label="Remove image" // Accessibility improvement
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      borderRadius: "50%",
                    }}
                  >
                    &times;
                  </Box>
                </CustomButton>
              </Box>
            ) : (
              <label>
                <input
                  ref={mobileImageRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "mobileImage")}
                />
                <CustomButton
                  variant="outlined"
                  size="small"
                  onClick={() => mobileImageRef.current?.click()}
                >
                  Upload Mobile Image
                </CustomButton>
                <TextField
                  label="Or Enter Image URL"
                  value={mobileUrl ?? (formValues.mobileImage || "")}
                  onBlurCapture={(e) => {
                    if (mobileUrl?.startsWith("https")) {
                      setFormValues((prev: any) => ({
                        ...prev,
                        mobileImage: mobileUrl,
                      }));
                    } else {
                      setMobileUrl("");
                      setFormValues((prev: any) => ({
                        ...prev,
                        mobileImage: "",
                      }));
                    }
                  }}
                  onChange={(e) => setMobileUrl(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </label>
            )}
          </Grid>

          {/* Desktop Image Upload */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Desktop Image
            </Typography>
            {formValues?.desktopImage ? (
              <Box
                position="relative"
                width="100%"
                height="auto"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <img
                    src={
                      typeof formValues.desktopImage === "string"
                        ? formValues.desktopImage
                        : URL.createObjectURL(formValues.desktopImage)
                    }
                    alt="Desktop Upload"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "500px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <CustomButton
                  variant="outlined"
                  color="warning"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    zIndex: 1,
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                    minWidth: "0",
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    setFormValues((prev: any) => ({
                      ...prev,
                      desktopImage: "",
                    }));
                  }}
                  aria-label="Remove image"
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      borderRadius: "50%",
                    }}
                  >
                    &times;
                  </Box>
                </CustomButton>
              </Box>
            ) : (
              <label>
                <input
                  ref={desktopImageRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "desktopImage")}
                />
                <CustomButton
                  variant="outlined"
                  size="small"
                  onClick={() => desktopImageRef.current?.click()}
                >
                  Upload Desktop Image
                </CustomButton>
                <TextField
                  label="Or Enter Image URL"
                  value={desktopUrl ?? (formValues.desktopImage || "")}
                  onBlurCapture={(e) => {
                    if (desktopUrl?.startsWith("https")) {
                      setFormValues((prev: any) => ({
                        ...prev,
                        desktopImage: desktopUrl,
                      }));
                    } else {
                      setDesktopUrl("");
                      setFormValues((prev: any) => ({
                        ...prev,
                        desktopImage: "",
                      }));
                    }
                  }}
                  onChange={(e) => setDesktopUrl(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </label>
            )}
          </Grid>

          {/* Images/Videos Section */}
          <Box marginTop={2}>
            <Typography variant="h6" gutterBottom>
              Gallery Images
            </Typography>
            {formValues?.images?.length > 0 && (
              <Grid container spacing={2}>
                {formValues?.images?.map((fileUrl: string, index: number) => (
                  <Grid item xs={6} sm={4} md={3} key={index}>
                    <Box
                      position="relative"
                      width="100%"
                      height="auto"
                      marginRight={20}
                    >
                      <Box>
                        {typeof fileUrl === "string" &&
                        fileUrl?.endsWith(".mp4") ? (
                          <video width="fit" controls>
                            <source src={fileUrl} type="video/mp4" />
                          </video>
                        ) : (
                          <img
                            src={
                              typeof fileUrl === "string"
                                ? fileUrl
                                : URL.createObjectURL(fileUrl)
                            }
                            alt={`event-${index}`}
                            style={{
                              width: "100%",
                              minWidth: "fit",
                              height: "200px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        )}
                      </Box>
                      <CustomButton
                        variant="outlined"
                        color="warning"
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                          zIndex: 1,
                          borderRadius: "50%",
                          width: "32px",
                          height: "32px",
                          minWidth: "0",
                          backgroundColor: "white",
                        }}
                        onClick={() => {
                          setFormValues((prev: any) => ({
                            ...prev,
                            images: prev.images.filter(
                              (_: any, i: number) => i !== index
                            ),
                          }));
                        }}
                        aria-label="Remove image"
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                          }}
                        >
                          &times;
                        </Box>
                      </CustomButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}

            <Box sx={{ marginY: "10px", display: "flex", gap: 2 }}>
              {/* Toggle between Upload and URL */}
              <CustomButton
                variant={useUrl ? "outlined" : "contained"}
                onClick={() => setUseUrl(false)}
              >
                Upload File
              </CustomButton>
              <CustomButton
                variant={useUrl ? "contained" : "outlined"}
                onClick={() => setUseUrl(true)}
              >
                Add via URL
              </CustomButton>
            </Box>

            {!useUrl ? (
              <Box
                sx={{
                  borderRadius: 2,
                  marginY: "10px",
                  border: "2px dashed #00C5B9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  overflow: "hidden",
                  height: "200px",
                }}
                onClick={() => fileInputRef.current?.click()}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*,video/*"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleMultipleFileChange}
                />
                <CustomButton
                  variant="outlined"
                  style={{ border: "none" }}
                  size="small"
                >
                  Upload Images/Videos
                </CustomButton>
              </Box>
            ) : (
              <Box sx={{ marginY: "10px" }}>
                <TextField
                  label="Enter URL"
                  value={galleryUrl}
                  onChange={(e) => setGalleryUrl(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <CustomButton
                  variant="contained"
                  onClick={handleAddUrl}
                  disabled={!galleryUrl.startsWith("https")}
                >
                  Add URL
                </CustomButton>
              </Box>
            )}
          </Box>
        </Grid>
        <Box marginTop={2}>
          {loading ? (
            <CircularProgress
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            <CustomButton onClick={handleSubmit}>
              {events ? "Update" : "Create"}
            </CustomButton>
          )}
        </Box>
      </form>
    </Container>
  );
};

export default EventsPage;
