import SignIn from "src/pages/Auth/SignIn";
import Users from "src/pages/Users";
import Pincode from "src/pages/Pincode";
import Events from "src/pages/Events";
import Category from "src/pages/Category";
import Faq from "src/pages/Faq";
import { ROUTES } from "./constant";
import Blog from "src/pages/Blog";
import Deal from "./pages/Deal";
import TestData from "./components/TestData";
import Popularlocations from "./pages/PopularLocation";
import Advertise from "./pages/Advertise";
import Business from "./pages/Business";
import ContactUs from "./pages/ContactUs";
import Tags from "./pages/Tags";
import SubcategoryTabs from "./pages/SubCategory";
import HomePageSettings from "./pages/HomePageSettings";
import TrackingData from "./pages/TrackingData";
import RegionTabs from "./pages/Region";
import ClaimBusiness from "./pages/ClaimBusiness";
import { getRole } from "./components/Common/Utils";
import BusinessProfileView from "./pages/Business/BusinessProfile";
import BusinessModal from "./pages/Business/BusinessModal";
import AllEnum from "./pages/Enum";
import BusinessSuperDetails from "./pages/Business/BusinessSuperDetails";
import ConfigDataList from "./pages/Config/ConfigDataList";

const isSuperAdmin = getRole() === "SuperAdmin";
const routes = [
  ...(isSuperAdmin
    ? [
        {
          path: ROUTES.USERS,
          name: "Users",
          component: Users,
          isPrivate: true,
          layout: "private",
        },
      ]
    : []),
  {
    path: ROUTES.LOGIN,
    name: "SignIn",
    component: SignIn,
    isPrivate: false,
    layout: "auth",
  },

  {
    path: ROUTES.PINCODES,
    name: "Pincode",
    component: Pincode,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.EVENTS,
    name: "Events",
    component: Events,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.CATEGORY,
    name: "Category",
    component: Category,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.FAQ,
    name: "Faq",
    component: Faq,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.BLOGS,
    name: "Blog",
    component: Blog,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.DEALS,
    name: "Deal",
    component: Deal,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.SUB_CATEGORY_DETAILS,
    name: "SubCategory",
    component: SubcategoryTabs,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.CATEGORY_DETAILS,
    name: "SubCategory",
    component: TestData,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.CATEGORY_LIST,
    name: "SubCategory",
    component: TestData,
  },
  {
    path: ROUTES.POPULAR_LOCATION,
    name: "PopularLocation",
    component: Popularlocations,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.ADVERTISE,
    name: "Advertise",
    component: Advertise,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.BUSINESS,
    name: "Business",
    component: Business,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.BUSSINESS_DETAILS,
    name: "Business",
    component: BusinessProfileView,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.BUSSINESS_CREATE,
    name: "Business Create",
    component: BusinessModal,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.BUSSINESS_EDIT,
    name: "Business Edit",
    component: BusinessModal,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.BUSSINESS_SUPER_DETAILS,
    name: "Business Super details",
    component: BusinessSuperDetails,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.CLAIM_BUSINESS,
    name: "Claim Business",
    component: ClaimBusiness,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.ADVERTISE,
    name: "Advertise",
    component: Advertise,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.CONTACT_US,
    name: "ContactUs",
    component: ContactUs,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.TAGS,
    name: "Tags",
    component: Tags,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.HOME_PAGE_SETTINGS,
    name: "Home Page",
    component: HomePageSettings,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.TRACKING_DATA,
    name: "Tracking Data",
    component: TrackingData,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.REGION,
    name: "Region",
    component: RegionTabs,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.ENUM,
    name: "Enum",
    component: AllEnum,
    isPrivate: true,
    layout: "private",
  },
  {
    path: ROUTES.CONFIG,
    name: "Config",
    component: ConfigDataList,
    isPrivate: true,
    layout: "private",
  },
];

export default routes;
