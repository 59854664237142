import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import Select from "react-select";
import {
  DELETE_EVENTS,
  UPDATE_EVENT_RANK,
  VERIFY_EVENTS,
} from "src/graphql/mutations";
import {
  GET_EVENTS,
  GET_EVENTS_BY_ID,
  GET_EVENTS_ENUM_ALL,
} from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import EventsModal from "./EventsModal";
import EventPage from "./EventPage";
import { toast } from "react-toastify";
import MenuIcon from "@mui/icons-material/Menu";
import {
  EventCategory as EventsCategory,
  EventPlatform as EventsPlatform,
  DateFilter,
  PriceFilter,
  dateFilterOptions,
  eventCategoryOptions as eventsCategoryOptions,
  eventPlatformOptions as eventsPlatformOptions,
  priceFilterOptions,
} from "./Events";
import VerifyEventsDialog from "./VerifyEvents";
import DeleteModel from "src/components/Common/DeleteModel";
import moment from "moment";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import SaveIcon from "@mui/icons-material/Save";
import { ClearIcon } from "@mui/x-date-pickers";
import {
  DELETE_All_EVENTS,
  DELETE_MULTIPLE_EVENTS,
} from "src/graphql/DeleteMutation";
import { getRole } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";

const Events: React.FC = () => {
  const [list, setList] = useState<any[]>([]);
  const [selectedevents, setSelectedEvents] = useState<any | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [isEventPageOpen, setIsEventPageOpen] = useState(false);
  const [eventPageData, setEventPageData] = useState<any>();
  const [category, setCategory] = useState<EventsCategory>(EventsCategory.All);
  const [platform, setPlatform] = useState<EventsPlatform>(EventsPlatform.All);
  const [dateFilter, setDateFilter] = useState<DateFilter>(DateFilter.All);
  const [priceFilter, setPriceFilter] = useState<PriceFilter>(PriceFilter.All);
  const [verifyingEvents, setVerifyingEvents] = useState<string | null>(null);
  const [reason, setReason] = useState<string>("");
  const [verifyEventsValue, setVerifyEventsValue] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState<string | null>(null);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  const [UpdateEventsRanksBulk] = useMutation(UPDATE_EVENT_RANK);
  const [VerifyEvent] = useMutation(VERIFY_EVENTS);
  const [DeleteMultipleEvents] = useMutation(DELETE_MULTIPLE_EVENTS);
  const [DeleteEvent] = useMutation(DELETE_EVENTS);
  const [DeleteAllEvents] = useMutation(DELETE_All_EVENTS);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const { loading, error, data, refetch } = useQuery(GET_EVENTS, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
      category,
      platform,
      dateFilter,
      priceFilter,
    },
  });

  const { refetch: refetchEventById } = useQuery(GET_EVENTS_BY_ID, {
    skip: true,
  });

  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
          },
        ]
      : []),
    {
      headerName: "Title",
      field: "title",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Box sx={{ cursor: "pointer" }} onClick={() => handleView(row)}>
          {row?.title}
        </Box>
      ),
    },
    {
      headerName: "Price",
      field: "price",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <span>&#8377;{row?.price[0]?.price}</span>
      ),
    },
    {
      headerName: "Date",
      field: "startDate",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <div>{moment(row?.startDate).format("DD-MM-YYYY")} </div>
      ),
    },
    {
      headerName: "Venue",
      field: "address[0]?.landmark",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <div>{row?.address?.[0]?.landmark} </div>
      ),
    },
    { headerName: "Status", field: "status", flex: 1 },
    {
      headerName: "Rank",
      field: "rank",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row.rank || "");

        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box>
          <IconButton
            onClick={() => handleEdit(row)}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleOpenDeleteModel(row._id)}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
          <Tooltip title="Verify Business">
            <CustomButton
              variant="outlined"
              color="primary"
              style={{ marginLeft: 2, width: "50px" }}
              onClick={() => setVerifyingEvents(row._id)}
            >
              Verify
            </CustomButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleEvents({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleEvents?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleEvents?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleEvents?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllEvents();
      if (res?.data?.deleteAllEvents?.statusCode === 200) {
        toast.success(res?.data?.deleteAllEvents?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllEvents?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term.length > 0 ? term : null);
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getEventsAll?.data) {
      setList(data.getEventsAll.data);
    }
    setTotal(data?.getEventsAll?.count);
  }, [data]);

  const handleView = async (rowData: { _id: string }) => {
    setIsEventPageOpen(true);
    try {
      const res = await refetchEventById({ _id: rowData?._id });
      setEventPageData(res?.data?.getEvent?.data);
    } catch (error) {
      console.error(error);
    }
    refetch();
  };

  const handleEdit = async (rowData: any) => {
    try {
      const res = await refetchEventById({ _id: rowData?._id });
      setSelectedEvents(res?.data?.getEvent?.data);
      setOpenModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenDeleteModel = (eventId: string) => {
    setDeleteEventId(eventId);
    setOpenDeleteModel(true);
  };

  const handleDelete = async () => {
    if (!deleteEventId) return;
    try {
      const res = await DeleteEvent({
        variables: { _id: deleteEventId },
      });
      if (res?.errors) {
        throw new Error(res?.errors as any);
      }
      toast.success(
        res?.data?.deleteEvent?.message || "Event deleted successfully"
      );
      refetch();
    } catch (error: any) {
      toast.error(
        error.message || "An error occurred while deleting the event."
      );
    } finally {
      setOpenDeleteModel(false);
      setDeleteEventId(null);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEvents(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };

  const handleCategoryChange = (option: any) => {
    setCategory(option.value);
    refetch({
      category: option.value,
      platform,
      dateFilter,
      priceFilter,
    });
  };

  const handlePlatformChange = (option: any) => {
    setPlatform(option.value);
    refetch({
      category,
      platform: option.value,
      dateFilter,
      priceFilter,
    });
  };

  const handleDateFilterChange = (option: any) => {
    setDateFilter(option.value);
    refetch({
      category,
      platform,
      dateFilter: option.value,
      priceFilter,
    });
  };

  const handlePriceFilterChange = (option: any) => {
    setPriceFilter(option.value);
    refetch({
      category,
      platform,
      dateFilter,
      priceFilter: option.value,
    });
  };

  const handleVerify = async (eventId: string, status: string) => {
    try {
      const res = await VerifyEvent({
        variables: {
          input: {
            _id: eventId,
            status,
            reason: reason,
          },
        },
      });
      if (res?.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error: any) => error.message)
            .join(", ")}`
        );
      }

      refetch();
      toast.success(res?.data?.verifyEvent?.message);
      setVerifyingEvents(null);
    } catch (error: any) {
      console.error("Error verifying business:", error);
      toast.error(error?.message);
    }
  };

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      eventId: id,
      rank: rankUpdates[id],
    }));

    try {
      const response = await UpdateEventsRanksBulk({
        variables: { input: { events: input } },
      });
      if (response?.data?.updateEventsRanksBulk?.statusCode === 200) {
        toast.success(response.data.updateEventsRanksBulk.message);
        setRankUpdates({});
      } else {
        throw new Error(response.data.updateEventsRanksBulk.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      refetch();
    }
  };
  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={3}>
      {!isEventPageOpen && !openModal && (
        <>
          <Drawer
            anchor="right"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            sx={{ width: 250, flexShrink: 0 }}
          >
            <Box p={2} width={250} mt={10}>
              <Typography
                variant="h4"
                gutterBottom
                style={{ color: "#00C5B9" }}
              >
                Filters
              </Typography>
              <Box my={4}>
                <Typography variant="h6" gutterBottom>
                  Category
                </Typography>
                <Select
                  options={eventsCategoryOptions}
                  value={eventsCategoryOptions.find(
                    (option) => option.value === category
                  )}
                  onChange={handleCategoryChange}
                  placeholder="Filter by Category"
                />
              </Box>
              <Box my={4}>
                <Typography variant="h6" gutterBottom>
                  Platform
                </Typography>
                <Select
                  options={eventsPlatformOptions}
                  value={eventsPlatformOptions.find(
                    (option) => option.value === platform
                  )}
                  onChange={handlePlatformChange}
                  placeholder="Filter by Platform"
                />
              </Box>
              <Box my={4}>
                <Typography variant="h6" gutterBottom>
                  Date
                </Typography>
                <Select
                  options={dateFilterOptions}
                  value={dateFilterOptions.find(
                    (option) => option.value === dateFilter
                  )}
                  onChange={handleDateFilterChange}
                  placeholder="Filter by Date"
                />
              </Box>
              <Box my={4}>
                <Typography variant="h6" gutterBottom>
                  Price
                </Typography>
                <Select
                  options={priceFilterOptions}
                  value={priceFilterOptions.find(
                    (option) => option.value === priceFilter
                  )}
                  onChange={handlePriceFilterChange}
                  placeholder="Filter by Price"
                />
              </Box>
            </Box>
          </Drawer>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              Events
            </Typography>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
              <Box width={200} mx={1}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <ActionMenu
                isSuperAdmin={isSuperAdmin}
                setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
                setOpenDeleteAllModel={setOpenDeleteAllModel}
              />
              {isRankEdit && (
                <CustomButton
                  onClick={handleRankUpdate}
                  variant="contained"
                  sx={{ width: 50 }}
                >
                  <SaveIcon />
                </CustomButton>
              )}
              <Box mx={2}>
                <CustomButton
                  onClick={() => setOpenModal(true)}
                  variant="contained"
                >
                  Create
                </CustomButton>
              </Box>
              <Box>
                <IconButton
                  onClick={() => setOpenDrawer(true)}
                  aria-label="menu"
                  sx={{ marginBottom: "8px" }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ mb: 3 }} />
          <CustomTable
            columns={COLUMNS}
            data={list}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            paginationModel={{
              page: pagination.page - 1,
              pageSize: pagination.pageSize,
            }}
            totalCount={total}
            onRowClick={() => {}}
          />
        </>
      )}

      {openModal && (
        <EventsModal
          handleClose={handleCloseModal}
          events={selectedevents}
          refetchEvents={refetch}
        />
      )}

      {isEventPageOpen && !openModal && (
        <EventPage
          data={eventPageData}
          onClose={() => setIsEventPageOpen(false)}
        />
      )}

      <VerifyEventsDialog
        label="Event"
        handleVerify={handleVerify}
        setVerifyingData={setVerifyingEvents}
        verifyingData={verifyingEvents}
        verifyDataValue={verifyEventsValue}
        reason={reason}
        setVerifyDataValue={setVerifyEventsValue}
        setReason={setReason}
      />

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this event?"
        />
      )}
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } events?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all events?"
        />
      )}
    </Box>
  );
};

export default Events;
