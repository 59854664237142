import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Divider,
  Tooltip,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ArticleIcon from "@mui/icons-material/Article";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";

import CustomTable from "src/components/CustomTable";
import DeleteModel from "src/components/Common/DeleteModel";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";

import { GET_CLAIM_BUSINESSES } from "src/graphql/query";
import {
  DELETE_CLAIM_STATUS,
  UPDATE_CLAIM_STATUS,
} from "src/graphql/mutations";
import styles from "./ClaimBusiness.module.css";
import ApprovalIcon from "@mui/icons-material/Approval";
import VerifyEventsDialog from "../Events/VerifyEvents";
import { debounce } from "src/utils/debounce";
function ClaimBusiness() {
  const [searchTerm, setSearchTerm] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [deleteClaimBusinessId, setDeleteClaimBusinessId] = useState<
    string | null
  >(null);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);

  const [DeleteClaimBusiness] = useMutation(DELETE_CLAIM_STATUS);
  const [verifyingByUsBusiness, setVerifyingByUsBusiness] = useState<
    string | null
  >(null);
  const [UpdateClaimBusinessStatus] = useMutation(UPDATE_CLAIM_STATUS);
  const [reason, setReason] = useState<string>("");
  const [verifyBusinessByUsProfileValue, setVerifyBusinessByUsProfileValue] =
    useState<string>("");
  const columns = [
    { field: "businessName", headerName: "Business Name", flex: 1 },
    { field: "designation", headerName: "Designation", flex: 1 },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "mobileNumber", headerName: "Mobile Number", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "verifiedBy", headerName: "Verified By", flex: 1 },
    {
      field: "verificationDate",
      headerName: "Verification Date",
      flex: 1,
      renderCell: (row: any) => (
        <Box>{moment(row?.verificationDate).format("DD-MM-YYYY")}</Box>
      ),
    },
    {
      field: "claimStatus",
      headerName: "Claim Status",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Tooltip title={row?.claimStatus ?? "PENDING"}>
          <IconButton
            aria-label="status"
            style={{ color: getStatusColor(row?.claimStatus) }}
          >
            {row?.claimStatus === "APPROVED" ? (
              <TaskAltIcon />
            ) : row?.claimStatus === "REJECTED" ? (
              <CloseIcon />
            ) : (
              <WarningIcon />
            )}
          </IconButton>
        </Tooltip>
      ),
    },
    { field: "gstinNumber", headerName: "GSTIN", flex: 1 },
    {
      headerName: "View Document",
      field: "proofDocument",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <Tooltip title="Download Document">
          <IconButton
            component="a"
            href={row?.proofDocument}
            download
            aria-label="download document"
          >
            <ArticleIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <Box display={"flex"} gap={2}>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeleteClaimBusinessId(row._id);
                setOpenDeleteModel(true);
              }}
              sx={{ color: "#00C5B9" }}
              aria-label="delete"
              className={styles.button}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Verify Business">
            <IconButton
              onClick={() => setVerifyingByUsBusiness(row._id)}
              aria-label="status"
              style={{ color: "#869986" }}
            >
              <ApprovalIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const { data, loading, error, refetch } = useQuery(GET_CLAIM_BUSINESSES, {
    variables: {
      page: Number(pagination.page) - 1 > 0 ? Number(pagination.page) : 1,
      pageSize: pagination.pageSize,
      search: searchTerm,
    },
  });

  useEffect(() => {
    if (data?.getClaimBusinesses?.data) {
      setList(data?.getClaimBusinesses?.data);
      setTotal(data?.getClaimBusinesses?.totalCount);
    }
  }, [data]);

  const handleClearSearch = () => {
    setSearchTerm("");
    refetch({
      page: pagination.page,
      pageSize: pagination.pageSize,
      searchTerm: searchTerm,
    });
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({ page: newPage, pageSize: pagination.pageSize, searchTerm });
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize }));
    refetch({ page: pagination.page, pageSize, searchTerm });
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteClaimBusinessId(null);
  };

  const handleDelete = async () => {
    if (!deleteClaimBusinessId) return;
    try {
      const res = await DeleteClaimBusiness({
        variables: { _id: deleteClaimBusinessId },
      });
      if (res?.data?.deleteClaimBusiness?.statusCode === 200) {
        toast.success(res?.data?.deleteClaimBusiness?.message);
      } else {
        throw new Error(res?.data?.deleteClaimBusiness?.message);
      }
      refetch();
      setOpenDeleteModel(false);
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error deleting business:", error);
    }
  };

  const handleVerifyByUs = async (businessId: string) => {
    try {
  
      const res = await UpdateClaimBusinessStatus({
        variables: {
          _id: businessId,
          claimStatus: verifyBusinessByUsProfileValue,
          reason,
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      refetch();
      toast.success(res?.data?.updateClaimBusinessStatus?.message);
      setVerifyingByUsBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };
  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        pr={4}
      >
        <Typography variant="h4" className={styles.primaryColor}>
          Claim Business
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Divider />
      {loading ? (
        <Loader />
      ) : error ? (
        <ErrorComponent />
      ) : (
        <CustomTable
          columns={columns}
          data={list}
          paginationModel={{
            page: Number(pagination.page - 1),
            pageSize: Number(pagination.pageSize),
          }}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          totalCount={total}
        />
      )}
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this claim business?"
        />
      )}

      <VerifyEventsDialog
        label="Business By us"
        handleVerify={handleVerifyByUs}
        setVerifyingData={setVerifyingByUsBusiness}
        verifyingData={verifyingByUsBusiness}
        verifyDataValue={verifyBusinessByUsProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessByUsProfileValue}
        setReason={setReason}
      />
    </Box>
  );
}

function getStatusColor(status: string) {
  switch (status) {
    case "APPROVED":
      return "#039487";
    case "REJECTED":
      return "#ff0000";
    default:
      return "#ff9966";
  }
}

export default ClaimBusiness;
