import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import * as XLSX from "xlsx";
import CustomButton from "src/components/CustomButton";
import { uploadExcel } from "src/components/Common/Utils";
import { toast } from "react-toastify";

interface ExcelData {
  [key: string]: string | number;
}

interface ErrorRow {
  rowIndex: number;
  message: string;
}

interface propsScript {
  onCancel: () => void;
  refetch: () => void;
}

const ExcelUploadModal = ({ onCancel, refetch }: propsScript) => {
  const [excelData, setExcelData] = useState<ExcelData[]>([]);
  const [fileName, setFileName] = useState<string | null>(null);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [errorRows, setErrorRows] = useState<ErrorRow[]>([]);
  const [selectedErrorRows, setSelectedErrorRows] = useState<number[]>([]);
  const [showErrorsDialog, setShowErrorsDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // New loading state
  const [excludePlaceIdErrors, setExcludePlaceIdErrors] = useState(false);
  const handleClose = () => {
    onCancel();
    resetState();
    refetch();
  };

  const resetState = () => {
    setExcelData([]);
    setErrorRows([]);
    setFileName(null);
    setExcelFile(null);
    setSelectedErrorRows([]);
    setShowErrorsDialog(false);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setExcelFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);
        setExcelData(jsonData as ExcelData[]);
        setFileName(file.name);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmit = async () => {
    try {
      if (excelFile) {
        setLoading(true);
        const response = await uploadExcel(excelFile);
        const {
          successfullyUploadedBusinesses,
          errorUploadedBusinesses,
          errors,
          message,
        } = response?.uploadOrUpdateBusinesses || {};

        if (successfullyUploadedBusinesses > 0) {
          toast.success(
            message ??
              `Successfully uploaded ${successfullyUploadedBusinesses} business profiles!`
          );
        }

        if (errorUploadedBusinesses > 0) {
          const errorRowsData = errors.map((error: any, index: number) => ({
            rowIndex: index,
            message: error.message,
          }));
          setErrorRows(errorRowsData);
          setShowErrorsDialog(true);
          toast.error(
            `Failed to upload ${errorUploadedBusinesses} businesses. Review the errors below.`
          );
        } else {
          refetch();
          handleClose();
        }
      } else {
        toast.error("No file selected. Please choose a file to upload.");
      }
    } catch (error) {
      console.error("File upload error:", error);
      toast.error("Error during file upload. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadExcel = () => {
    // Filter out error rows based on `excludePlaceIdErrors`
    const filteredErrorIndices = errorRows
      .map((error, index) => {
        if (
          excludePlaceIdErrors &&
          error?.message?.includes("already exists for row with data")
        ) {
          return null; // Exclude this row
        }
        return selectedErrorRows.includes(index) ? index : null;
      })
      .filter((index) => index !== null); // Remove null values

    // Map through filtered rows to combine excel data and error messages
    const combinedData = filteredErrorIndices.map((rowIndex) => {
      const rowData = excelData[rowIndex as any] || {};
      const errorData = errorRows[rowIndex as any];

      return {
        ...rowData,
        Error: errorData ? errorData.message : "No error",
      };
    });

    // Create the Excel worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(combinedData);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, "FilteredData");

    // Format the filename and write the file
    const formattedFileName = `${fileName?.split(".")[0]}-REJECT.xlsx`;
    XLSX.writeFile(newWorkbook, formattedFileName);
  };

  const toggleSelectErrorRow = (rowIndex: number) => {
    setSelectedErrorRows((prevSelected) =>
      prevSelected.includes(rowIndex)
        ? prevSelected.filter((index) => index !== rowIndex)
        : [...prevSelected, rowIndex]
    );
  };

  const handleSelectAllErrors = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    if (checked) {
      const allErrorRowIndices = errorRows.map((_, index) => index);
      setSelectedErrorRows(allErrorRowIndices);
    } else {
      setSelectedErrorRows([]);
    }
  };

  return (
    <Box>
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{ marginTop: "20px" }}
      >
        <DialogTitle>Upload and Review Excel Data</DialogTitle>
        <DialogContent>
          <input
            accept=".xlsx, .xls"
            style={{ display: "none" }}
            id="excel-upload"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="excel-upload">
            <CustomButton variant="contained" component="span" fullWidth>
              {fileName ? `File: ${fileName}` : "Choose Excel File"}
            </CustomButton>
          </label>

          {excelData.length > 0 && !showErrorsDialog && (
            <>
              <Typography variant="h6" marginTop={2}>
                Preview of Uploaded Data:{excelData.length}
              </Typography>
              <Table sx={{ marginTop: 2, boxShadow: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Index</TableCell>
                    {Object.keys(excelData[0]).map((key) => (
                      <TableCell key={key} sx={{ fontWeight: "bold" }}>
                        {key}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {excelData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell key={index}>{index}</TableCell>
                      {Object.values(row).map((value, idx) => (
                        <TableCell key={idx}>{value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}

          {showErrorsDialog && errorRows.length > 0 && (
            <>
              <Typography variant="h6" marginTop={2} color="error">
                Errors:{errorRows.length}{" "}
                <Checkbox
                  checked={excludePlaceIdErrors}
                  onChange={() =>
                    setExcludePlaceIdErrors(!excludePlaceIdErrors)
                  }
                />
                <span style={{ color: "#000000", fontSize: "18px" }}>
                  Exclude placeId exist errors
                </span>
              </Typography>
              <Table sx={{ marginTop: 2, boxShadow: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        onChange={handleSelectAllErrors}
                        checked={selectedErrorRows.length === errorRows.length}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Row Index</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Error Message
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedErrorRows.includes(row.rowIndex)}
                          onChange={() => toggleSelectErrorRow(row.rowIndex)}
                        />
                      </TableCell>
                      <TableCell>{row.rowIndex}</TableCell>
                      <TableCell>{row.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          {loading && (
            <Box display="flex" justifyContent="center" marginTop={2}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between" width="100%">
            <CustomButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={!excelFile || loading}
              sx={{ flex: 1, marginRight: 1 }} // Full width with margin
            >
              {loading ? "Uploading..." : "Upload File"}
            </CustomButton>
            {showErrorsDialog && errorRows.length > 0 && (
              <CustomButton
                onClick={handleDownloadExcel}
                variant="contained"
                color="secondary"
                disabled={selectedErrorRows.length === 0}
                sx={{ flex: 1, marginRight: 1 }} // Full width
              >
                Download Selected
              </CustomButton>
            )}
            <CustomButton
              onClick={handleClose}
              color="inherit"
              sx={{ flex: 1, color: "#ffffff" }}
            >
              Cancel
            </CustomButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExcelUploadModal;
